import { Button, Stack, Typography } from "@mui/material";
import { ActionsSection } from "../DialogComponents";
import { ViewHeading } from "./ViewHeading";
import { Kind } from "../../graphql/generated";
import { ResourceTypeButton } from "../ResourceTypeButton";
import { ResourceTypeButtonContainer } from "../ResourceTypeButton";
import { DialogResource } from "../ResourceDialog";
import { ReusableProcessors } from "../ProcessorsDialog/ProcessorDialog";
import { ResourceType } from "./ResourceConfigurationEditor";
import { useState } from "react";
import { ProcessorIcon } from "../Icons";
import { metadataSatisfiesSubstring } from "../../utils/metadata-satisfies-substring";
import { Stability } from "../../types/resources";

interface ChooseViewProps {
  reusableResources: ReusableProcessors; // or extensions
  handleSaveExisting: (r: DialogResource) => void;
  selected: ResourceType;
  resourceKind: Kind.Processor | Kind.Extension;
  onBack: () => void;
  onCreate: () => void;
}

export const ChooseView: React.FC<ChooseViewProps> = ({
  reusableResources,
  selected,
  resourceKind,
  handleSaveExisting,
  onBack,
  onCreate,
}) => {
  const [search, setSearch] = useState("");

  return (
    <Stack spacing={2} height={"100%"}>
      <ViewHeading
        heading={
          selected?.metadata?.displayName && resourceKind
            ? `Add ${resourceKind}: ${selected.metadata.displayName}`
            : selected?.metadata?.name
        }
        subHeading={selected?.metadata?.description || ``}
        stability={selected?.metadata?.stability ?? Stability.UNKNOWN}
        resourceDocLink={selected?.metadata?.resourceDocLink || ``}
      />
      <Stack spacing={1} alignItems={"center"} overflow={"auto"} flexGrow={1}>
        <Button
          variant="contained"
          color="primary"
          onClick={onCreate}
          data-testid="add-resource-page-create-new-instead-of-reuse-button"
        >
          Create New
        </Button>
        <Typography>or</Typography>
        <Typography>Reuse existing {resourceKind}</Typography>
        <ResourceTypeButtonContainer
          onSearchChange={(v: string) => setSearch(v)}
          placeholder={
            resourceKind === Kind.Processor
              ? "Search for a processor..."
              : resourceKind === Kind.Extension
                ? "Search for an extension..."
                : ""
          }
        >
          {reusableResources
            .filter((r) => {
              return metadataSatisfiesSubstring(r, search);
            })
            .map((resource) => {
              return (
                <ResourceTypeButton
                  key={resource?.metadata?.name}
                  displayName={resource?.metadata?.name}
                  iconOverrideSVG={<ProcessorIcon />} // temporary until we add icons to processor metadata or add support for reusable extensions
                  onSelect={() => handleSaveExisting(resource)}
                  telemetryTypes={selected?.spec?.telemetryTypes}
                />
              );
            })}
        </ResourceTypeButtonContainer>
      </Stack>
      <ActionsSection>
        <Button variant="outlined" color="secondary" onClick={() => onBack()}>
          Back
        </Button>
      </ActionsSection>
    </Stack>
  );
};

import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CardContainer } from "../../components/CardContainer";
import { PlusCircleIcon } from "../../components/Icons";
import { classes } from "../../utils/styles";
import { deleteAgents } from "../../utils/rest/delete-agents";
import { useSnackbar } from "notistack";
import { ConfirmDeleteResourceDialog } from "../../components/ConfirmDeleteResourceDialog";
import { withRequireLogin } from "../../contexts/RequireLogin";
import { isFunction } from "lodash";
import { upgradeAgents } from "../../utils/rest/upgrade-agent";
import {
  Role,
  useGetAgentMonitoringSummaryQuery,
  useGetFeatureGateQuery,
} from "../../graphql/generated";
import { hasPermission } from "../../utils/has-permission";
import { useRole } from "../../hooks/useRole";
import { RBACWrapper } from "../../components/RBACWrapper/RBACWrapper";
import { withRBAC } from "../../contexts/RBAC";
import { withEENavBar } from "../../components/EENavBar";
import { EEAgentsTable } from "../../components/EEAgentsTable";
import mixins from "../../styles/mixins.module.scss";
import styles from "./agent-banner.module.scss";
import { LimitConnectedAgentsDialog } from "../../components/LimitConnectedAgentsDialog";
import { MultiAgentLabelEditor } from "../../components/MultiAgentLabelEditor";

export const AGENTS_PAGE_QUERY_PARAM = "query";

export const AgentsPageContent: React.FC = () => {
  const [updatable, setUpdatable] = useState<GridRowSelectionModel>([]);
  const [deletable, setDeletable] = useState<GridRowSelectionModel>([]);
  const [labelable, setLabelable] = useState<GridRowSelectionModel>([]);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const [connectedAgentCount, setConnectedAgentCount] = useState(0);
  const [unlimitedAgentsGate, setUnlimitedAgentsGate] = useState<boolean>();
  const [limitedAgentsOpen, setLimitedAgentsOpen] = useState(false);
  const [selectedAgentLabels, setSelectedAgentLabels] = useState<
    Record<string, string>[]
  >([]);

  const clearSelectionModelFnRef = useRef<(() => void) | null>(null);

  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const role = useRole();
  const navigate = useNavigate();

  useGetAgentMonitoringSummaryQuery({
    onCompleted(data) {
      setConnectedAgentCount(data.agentMonitoring.connected);
    },
    onError(err) {
      console.error(err);
    },
    pollInterval: 5000,
  });

  useGetFeatureGateQuery({
    variables: {
      input: "unlimited-agents",
    },
    onCompleted(data) {
      setUnlimitedAgentsGate(data.enabled);
    },
    onError(err) {
      console.error(err);
    },
  });

  function handleSelectUpdatable(agentIds: GridRowSelectionModel) {
    setUpdatable(agentIds);
  }
  function handleSelectDeletable(agentIds: GridRowSelectionModel) {
    setDeletable(agentIds);
  }

  function handleSelectLabelable(
    agentIds: GridRowSelectionModel,
    agentLabels: Record<string, string>[],
  ) {
    setLabelable(agentIds);
    setSelectedAgentLabels(agentLabels);
  }

  async function handleInstallAgentClick() {
    if (unlimitedAgentsGate || connectedAgentCount < 100) {
      navigate({
        pathname: "/agents/install",
      });
    } else {
      setLimitedAgentsOpen(true);
    }
  }

  async function handleDeleteAgents() {
    try {
      await deleteAgents(deletable as string[]);
      clearSelected();
      setDeleteConfirmOpen(false);
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to delete agents.", { variant: "error" });
    }
  }

  async function handleUpgradeAgents() {
    try {
      const errors = await upgradeAgents(updatable as string[]);

      if (isFunction(clearSelectionModelFnRef.current)) {
        clearSelectionModelFnRef.current();
      }

      clearSelected();

      if (errors.length > 0) {
        console.error("Upgrade errors.", { errors });
      }
    } catch (err) {
      enqueueSnackbar("Failed to send upgrade request.", {
        variant: "error",
        key: "Failed to send upgrade request.",
      });
    }
  }

  function clearSelected() {
    setDeletable([]);
    setLabelable([]);
    setUpdatable([]);
    if (isFunction(clearSelectionModelFnRef.current)) {
      clearSelectionModelFnRef.current();
    }
  }

  const initQuery =
    new URLSearchParams(location.search).get(AGENTS_PAGE_QUERY_PARAM) ?? "";

  return (
    <>
      {/* --------------------- Delete Button and Confirmation --------------------- */}
      <ConfirmDeleteResourceDialog
        onDelete={handleDeleteAgents}
        onCancel={() => setDeleteConfirmOpen(false)}
        action={"delete"}
        open={deleteConfirmOpen}
        title={`Delete ${deletable.length} Disconnected Agent${
          deletable.length > 1 ? "s" : ""
        }?`}
      >
        <>
          <Typography>
            Agents will reappear in BindPlane OP if reconnected.
          </Typography>
        </>
      </ConfirmDeleteResourceDialog>
      {/* -------------------------- Agent Limit Dialogue -------------------------- */}
      <LimitConnectedAgentsDialog
        title="Agent Limit Reached"
        onClose={() => setLimitedAgentsOpen(false)}
        open={limitedAgentsOpen}
      >
        <>
          <Typography>
            You've reached the 100-agent limit in the Free edition of BindPlane.
            Upgrade to Enterprise to manage an unlimited number of agents.
          </Typography>
          <br />
          <a
            href="https://observiq.com/request-demo"
            target="_blank"
            rel="noreferrer"
          >
            Upgrade to Enterprise
          </a>
        </>
      </LimitConnectedAgentsDialog>
      {/* --------------------------- Agent Limit Banner --------------------------- */}
      {connectedAgentCount >= 100 && unlimitedAgentsGate === false && (
        <Alert severity="error" className={classes([styles.banner])}>
          <Typography fontWeight={600}>
            You've reached the 100-agent limit in the Free edition of BindPlane.
            Upgrade to Enterprise to manage an unlimited number of agents.{" "}
            <a
              href="https://observiq.com/request-demo"
              target="_blank"
              rel="noreferrer"
            >
              Upgrade to Enterprise
            </a>
          </Typography>
        </Alert>
      )}
      <CardContainer>
        <Box style={{ height: "calc(100vh - 200px)" }}>
          <Stack height="100%">
            {/* --------------------- Upgrade Button and Confirmation ---------------------  */}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              height="48px"
              marginBottom="16px"
            >
              <Typography variant="h5">Agents</Typography>
              <Stack direction={"row"} spacing={2} justifyContent="flex-end">
                {labelable.length > 0 && (
                  <MultiAgentLabelEditor
                    agentIds={labelable as string[]}
                    selectedAgentLabels={selectedAgentLabels}
                    onSave={clearSelected}
                  />
                )}

                {updatable.length > 0 && (
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={handleUpgradeAgents}
                  >
                    Upgrade {updatable.length} Outdated Agent
                    {updatable.length > 1 && "s"}
                  </Button>
                )}
                {deletable.length > 0 ? (
                  <Button
                    size="small"
                    variant="contained"
                    color="error"
                    onClick={() => setDeleteConfirmOpen(true)}
                  >
                    Delete {deletable.length} Disconnected Agent
                    {deletable.length > 1 && "s"}
                  </Button>
                ) : (
                  <RBACWrapper requiredRole={Role.User}>
                    <Button
                      size="small"
                      variant={"contained"}
                      classes={{ root: mixins["float-right"] }}
                      onClick={() => handleInstallAgentClick()}
                      startIcon={<PlusCircleIcon height={20} width={20} />}
                      data-testid="install-agent-button"
                    >
                      Install Agent
                    </Button>
                  </RBACWrapper>
                )}
              </Stack>
            </Stack>
            <Stack height="calc(100% - 64px)">
              <EEAgentsTable
                allowSelection={hasPermission(Role.User, role)}
                onDeletableAgentsSelected={handleSelectDeletable}
                onUpdatableAgentsSelected={handleSelectUpdatable}
                onLabelableAgentsSelected={handleSelectLabelable}
                clearSelectionModelFnRef={clearSelectionModelFnRef}
                initQuery={initQuery}
                urlQuerySearchParam={AGENTS_PAGE_QUERY_PARAM}
                onboardingNoRowsOverlay
              />
            </Stack>
          </Stack>
        </Box>
      </CardContainer>
    </>
  );
};

export const AgentsPage = withRequireLogin(
  withRBAC(withEENavBar(AgentsPageContent)),
);

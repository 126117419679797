import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { isFunction } from "lodash";
import { memo, useState } from "react";
import { ParamInputProps } from "./ParameterInput";
import { HelpCircleIcon } from "../../Icons";

import styles from "./parameter-input.module.scss";
import colors from "../../../styles/colors";

export const TelemetrySelectorInput: React.FC<
  ParamInputProps<string[] | string>
> = memo(({ definition, value, readOnly, onValueChange }) => {
  const [values, setValues] = useState<string[] | string | undefined>(value);
  const exclusive =
    definition.options?.variant?.localeCompare("exclusive") === 0;

  function handleValueChange(
    _e: React.MouseEvent<HTMLElement>,
    newSelections: string[] | string,
  ) {
    if (exclusive && newSelections === null) {
      return;
    }
    setValues(newSelections);
    isFunction(onValueChange) && onValueChange(newSelections);
  }
  return (
    <Stack spacing={1} direction="row" alignItems="center">
      <Typography variant="h6">{definition.label}:</Typography>
      <ToggleButtonGroup
        onChange={handleValueChange}
        aria-label="telemetry type"
        value={values}
        color="primary"
        exclusive={exclusive ?? false}
      >
        {definition.validValues?.map((v) => (
          <ToggleButton
            className={styles["chip-button"]}
            key={`chip-toggle-${v}`}
            value={v}
            aria-label={v}
            disabled={readOnly}
          >
            {v}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {exclusive && (
        <Tooltip title={definition.description}>
          <span className={styles.span}>
            <HelpCircleIcon color={colors.middleDarkGray} />
          </span>
        </Tooltip>
      )}
    </Stack>
  );
});

import { gql } from "@apollo/client";
import { createContext, useContext, useState } from "react";
import { useGetDestinationSummaryTopQuery } from "../../../graphql/generated";

export const ALL_DESTINATIONS_VALUE = "__all__";

export enum SummaryInterval {
  OneHour = "1h",
  OneDay = "24h",
  ThirtyDays = "720h",
  NinetyDays = "2160h",
}

export enum SummaryPeriod {
  OneMinute = "1m",
  OneHour = "1h",
  OneDay = "24h",
}

export interface SummaryPageContextValue {
  measurementInterval: SummaryInterval;
  onMeasurementIntervalChange: (i: SummaryInterval) => void;
  telemetryType?: string;
  onTelemetryTypeChange: (t: string) => void;
  period: SummaryPeriod;
  onPeriodChange: (p: SummaryPeriod) => void;
  destination?: string;
  onDestinationChange: (d: string) => void;
}

const defaultContext: SummaryPageContextValue = {
  measurementInterval: SummaryInterval.OneHour,
  onMeasurementIntervalChange: () => {},
  telemetryType: "logs",
  onTelemetryTypeChange: () => {},
  period: SummaryPeriod.OneMinute,
  onPeriodChange: () => {},
  destination: ALL_DESTINATIONS_VALUE,
  onDestinationChange: () => {},
};

gql`
  query GetDestinationSummaryTop {
    summaryTopDestinationAndType(interval: "1h") {
      topDestinationName
      topTelemetryType
    }
  }
`;

const SummaryPageContext = createContext(defaultContext);

export const SummaryPageContextProvider: React.FC = ({ children }) => {
  const [measurementInterval, onMeasurementIntervalChange] =
    useState<SummaryInterval>(SummaryInterval.OneHour);
  const [telemetryType, setTelemetryType] = useState<string>();
  const [period, setPeriod] = useState<SummaryPeriod>(SummaryPeriod.OneMinute);

  const [destination, setDestination] = useState<string | undefined>();

  useGetDestinationSummaryTopQuery({
    onCompleted(data) {
      setDestination(
        data.summaryTopDestinationAndType.topDestinationName === ""
          ? ALL_DESTINATIONS_VALUE
          : data.summaryTopDestinationAndType.topDestinationName,
      );
      setTelemetryType(data.summaryTopDestinationAndType.topTelemetryType);
    },
    skip: destination != null,
  });

  return (
    <SummaryPageContext.Provider
      value={{
        measurementInterval,
        onMeasurementIntervalChange,
        telemetryType,
        onTelemetryTypeChange: setTelemetryType,
        period,
        onPeriodChange: setPeriod,
        destination,
        onDestinationChange: setDestination,
      }}
    >
      {children}
    </SummaryPageContext.Provider>
  );
};

export function useSummaryPageContext(): SummaryPageContextValue {
  return useContext(SummaryPageContext);
}

import { gql } from "@apollo/client";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { ConfirmDeleteResourceDialog } from "../../components/ConfirmDeleteResourceDialog";
import { withEENavBar } from "../../components/EENavBar";
import { ChevronDown } from "../../components/Icons";
import { DEFAULT_CONFIGURATION_TABLE_QUERY_PERIOD } from "../../components/MeasurementControlBar/MeasurementControlBar";
import {
  DestinationsDataGrid,
  DestinationsTableField,
} from "../../components/Tables/DestinationsTable/DestinationsDataGrid";
import { EditDestinationDialog } from "../../components/Tables/DestinationsTable/EditDestinationDialog";
import { FailedDeleteDialog } from "../../components/Tables/DestinationsTable/FailedDeleteDialog";
import { EditProcessorDialog } from "../../components/Tables/ProcessorTable/EditProcessorDialog";
import { ProcessorDataGrid } from "../../components/Tables/ProcessorTable/ProcessorDataGrid";
import { EditSourceDialog } from "../../components/Tables/SourceTable/EditSourceDialog";
import { SourceDataGrid } from "../../components/Tables/SourceTable/SourceDataGrid";
import {
  RBACWrapper,
  hasPermission,
  useRole,
  withRBAC,
} from "../../contexts/RBAC";
import { withRequireLogin } from "../../contexts/RequireLogin";
import {
  Kind,
  Role,
  useConfigurationTableMetricsSubscription,
  useDestinationsAndTypesQuery,
  useProcessorsAndTypesQuery,
  useSourcesAndTypesQuery,
} from "../../graphql/generated";
import { ResourceKind, ResourceStatus } from "../../types/resources";
import { deleteResources } from "../../utils/rest/delete-resources";
import { resourcesFromSelected } from "../destinations/DestinationsPage";
import { classes } from "../../utils/styles";

import mixins from "../../styles/mixins.module.scss";
import styles from "./resource-library.module.scss";
import {
  NewResourceDialog,
  ResourceType,
} from "../../components/ResourceDialog";
import { AddToLibraryDialog } from "../../components/Dialogs/AddToLibraryDialog";
import { BPDestination, BPProcessor, BPSource } from "../../utils/classes";
import { applyResources } from "../../utils/rest/apply-resources";
import { ValidationContextProvider } from "../../components/ResourceConfigForm";

gql`
  query Sources {
    sources {
      metadata {
        id
        name
        displayName
        version
      }
      spec {
        type
      }
    }
  }

  query Processors {
    processors {
      metadata {
        id
        name
        displayName
        version
      }
      spec {
        type
      }
    }
  }

  query ProcessorsAndTypes {
    processors {
      metadata {
        id
        name
        displayName
        version
      }
      spec {
        type
      }
    }
    processorTypes {
      apiVersion
      kind
      metadata {
        id
        name
        version
        displayName
        description
        icon
        additionalInfo {
          message
          documentation {
            text
            url
          }
        }
        resourceDocLink
      }
      spec {
        parameters {
          name
          label
          description
          relevantIf {
            name
            operator
            value
          }
          documentation {
            text
            url
          }
          advancedConfig
          required
          type
          validValues
          default
          options {
            creatable
            multiline
            trackUnchecked
            sectionHeader
            subHeader
            horizontalDivider
            gridColumns
            labels
            metricCategories {
              label
              column
              metrics {
                name
                description
                kpi
              }
            }
            password
            sensitive
            variant
          }
        }
        supportedPlatforms
        version
        telemetryTypes
      }
    }
  }
`;

/**
 * Tables of reusable sources, processors, and destinations
 */
const ResourceLibraryPageContent: React.FC = () => {
  const role = useRole();
  const { enqueueSnackbar } = useSnackbar();
  const { data: sourcesAndTypes, refetch: refetchSourcesAndTypes } =
    useSourcesAndTypesQuery({
      onError: (e) => {
        console.error(e);
        enqueueSnackbar("There was an error retrieving sources.", {
          variant: "error",
        });
      },
    });
  const { data: processorsAndTypes, refetch: refetchProcessorsAndTypes } =
    useProcessorsAndTypesQuery({
      onError: (e) => {
        console.error(e);
        enqueueSnackbar("There was an error retrieving processors.", {
          variant: "error",
        });
      },
    });
  const {
    data: destinationsAndTypes,
    loading: loadingDestinationsAndTypes,
    refetch: refetchDestinationsAndTypes,
  } = useDestinationsAndTypesQuery({
    fetchPolicy: "cache-and-network",
    refetchWritePolicy: "merge",
    onError: (e) => {
      console.error(e);
      enqueueSnackbar("There was an error retrieving destinations.", {
        variant: "error",
      });
    },
  });
  const { data: destinationMetrics } = useConfigurationTableMetricsSubscription(
    {
      variables: {
        period: DEFAULT_CONFIGURATION_TABLE_QUERY_PERIOD,
      },
    },
  );
  const [editingSource, setEditingSource] = useState<string | null>(null);
  const [editingProcessor, setEditingProcessor] = useState<string | null>(null);
  const [editingDestination, setEditingDestination] = useState<string | null>(
    null,
  );
  const [addSourceDialogOpen, setAddSourceDialogOpen] =
    useState<boolean>(false);
  const [addProcessorDialogOpen, setAddProcessorDialogOpen] =
    useState<boolean>(false);
  const [addDestinationDialogOpen, setAddDestinationDialogOpen] =
    useState<boolean>(false);
  const [addToLibraryDialogOpen, setAddToLibraryDialogOpen] =
    useState<boolean>(false);
  const [addKind, setAddKind] = useState<
    | Kind.Configuration
    | Kind.Processor
    | Kind.Extension
    | Kind.Source
    | Kind.Destination
  >();
  const [existingResourceNames, setExistingResourceNames] = useState<string[]>(
    [],
  );
  const [addParameters, setAddParameters] = useState<{ [key: string]: any }>();
  const [addResourceType, setAddResourceType] = useState<string>();
  // Used to control the delete confirmation modal.
  const [open, setOpen] = useState<boolean>(false);
  const [deletingKind, setDeletingKind] = useState<ResourceKind | null>(null);
  const [selectedSources, setSelectedSources] = useState<GridRowSelectionModel>(
    [],
  );
  const [selectedProcessors, setSelectedProcessors] =
    useState<GridRowSelectionModel>([]);
  const [selectedDestinations, setSelectedDestinations] =
    useState<GridRowSelectionModel>([]);
  const [failedDeletes, setFailedDeletes] = useState<ResourceStatus[]>([]);
  const [failedDeletesOpen, setFailedDeletesOpen] = useState(false);

  const selectedForDeletion = useMemo(() => {
    switch (deletingKind) {
      case ResourceKind.SOURCE:
        return selectedSources;
      case ResourceKind.PROCESSOR:
        return selectedProcessors;
      case ResourceKind.DESTINATION:
        return selectedDestinations;
      default:
        return [];
    }
  }, [deletingKind, selectedSources, selectedProcessors, selectedDestinations]);

  async function deleteLibraryResources() {
    const selected = selectedForDeletion;
    try {
      const items = resourcesFromSelected(selected, deletingKind!);
      const { updates } = await deleteResources(items);
      setOpen(false);

      const failures = updates.filter((u) => u.status !== "deleted");
      setFailedDeletes(failures);

      refetchSourcesAndTypes();
      refetchProcessorsAndTypes();
      refetchDestinationsAndTypes();
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to delete resources.", { variant: "error" });
    }
  }

  useEffect(() => {
    refetchSourcesAndTypes();
    refetchProcessorsAndTypes();
  });

  useEffect(() => {
    if (failedDeletes.length > 0) {
      setFailedDeletesOpen(true);
    }
  }, [failedDeletes, setFailedDeletesOpen]);

  function onAcknowledge() {
    setFailedDeletesOpen(false);
  }

  // when arriving from Overview
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#destinations") {
      const targetElement = document.getElementById("destinations");
      if (targetElement) {
        setTimeout(() => {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }, 500);
      }
    }
  }, []);

  function handleSaveNewSource(
    parameters: { [key: string]: any },
    resourceType: ResourceType,
    type?: string,
  ) {
    setAddKind(Kind.Source);
    const sourceNames = sourcesAndTypes?.sources.map((s) => {
      return s.metadata.name;
    });
    setExistingResourceNames(sourceNames ?? []);
    setAddParameters(parameters);
    setAddResourceType(type ?? "");
    setAddToLibraryDialogOpen(true);
  }

  async function handleAddNewSource(name: string) {
    const librarySource = new BPSource({
      metadata: {
        name: name,
        id: "",
        version: 0,
        displayName: addParameters!.displayName,
      },
      spec: {
        parameters: [],
        type: addResourceType!,
        disabled: false,
      },
    });

    librarySource.setParamsFromMap(addParameters!);

    try {
      await applyResources([librarySource]);
      enqueueSnackbar("Successfully added Source to Library!", {
        variant: "success",
        autoHideDuration: 3000,
      });
      setAddKind(undefined);
      setExistingResourceNames([]);
      setAddParameters([]);
      setAddResourceType(undefined);
      setAddToLibraryDialogOpen(false);
      setAddSourceDialogOpen(false);
      refetchSourcesAndTypes();
    } catch (err) {
      enqueueSnackbar("Failed to add Source to Library.", {
        variant: "error",
        autoHideDuration: 5000,
      });
      console.error(err);
      return;
    }
  }

  function handleSaveNewDestination(
    parameters: { [key: string]: any },
    resourceType: ResourceType,
    type?: string,
  ) {
    setAddKind(Kind.Destination);
    const destinationNames = destinationsAndTypes?.destinations.map((d) => {
      return d.metadata.name;
    });
    setExistingResourceNames(destinationNames ?? []);
    setAddParameters(parameters);
    setAddResourceType(type ?? "");
    setAddToLibraryDialogOpen(true);
  }

  async function handleAddNewDestination(name: string) {
    const libraryDestination = new BPDestination({
      metadata: {
        name: name,
        id: "",
        version: 0,
        displayName: addParameters!.displayName,
      },
      spec: {
        parameters: [],
        type: addResourceType!,
        disabled: false,
      },
    });

    libraryDestination.setParamsFromMap(addParameters!);

    try {
      await applyResources([libraryDestination]);
      enqueueSnackbar("Successfully added Destination to Library!", {
        variant: "success",
        autoHideDuration: 3000,
      });
      setAddKind(undefined);
      setExistingResourceNames([]);
      setAddParameters([]);
      setAddResourceType(undefined);
      setAddToLibraryDialogOpen(false);
      setAddDestinationDialogOpen(false);
      refetchDestinationsAndTypes();
    } catch (err) {
      enqueueSnackbar("Failed to add Destination to Library.", {
        variant: "error",
        autoHideDuration: 5000,
      });
      console.error(err);
      return;
    }
  }

  function handleSaveNewProcessor(
    parameters: { [key: string]: any },
    resourceType: ResourceType,
    type?: string,
  ) {
    setAddKind(Kind.Processor);
    const processorNames = processorsAndTypes?.processors.map((p) => {
      return p.metadata.name;
    });
    setExistingResourceNames(processorNames ?? []);
    setAddParameters(parameters);
    setAddResourceType(type ?? "");
    setAddToLibraryDialogOpen(true);
  }

  async function handleAddNewProcessor(name: string) {
    const libraryProcessor = new BPProcessor({
      metadata: {
        name: name,
        id: "",
        version: 0,
        displayName: addParameters!.displayName,
      },
      spec: {
        parameters: [],
        type: addResourceType!,
        disabled: false,
      },
    });

    libraryProcessor.setParamsFromMap(addParameters!);

    try {
      await applyResources([libraryProcessor]);
      enqueueSnackbar("Successfully added Processor to Library!", {
        variant: "success",
        autoHideDuration: 3000,
      });
      setAddKind(undefined);
      setExistingResourceNames([]);
      setAddParameters([]);
      setAddResourceType(undefined);
      setAddToLibraryDialogOpen(false);
      setAddProcessorDialogOpen(false);
      refetchProcessorsAndTypes();
    } catch (err) {
      enqueueSnackbar("Failed to add Processor to Library.", {
        variant: "error",
        autoHideDuration: 5000,
      });
      console.error(err);
      return;
    }
  }

  const addFunc =
    addKind === "Source"
      ? handleAddNewSource
      : addKind === "Destination"
        ? handleAddNewDestination
        : handleAddNewProcessor;

  return (
    <ValidationContextProvider initErrors={{}} definitions={[]}>
      <Stack spacing={3} className={classes([styles.root])}>
        <Accordion defaultExpanded className={classes([styles.accordion])}>
          <AccordionSummary expandIcon={<ChevronDown />}>
            <Stack
              direction={"row"}
              flexGrow={1}
              justifyContent={"space-between"}
              marginRight={"8px"}
            >
              <Typography variant="h5">Sources</Typography>
              <Stack direction={"row"} spacing={2}>
                <RBACWrapper requiredRole={Role.User}>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={(e) => {
                      e.stopPropagation(); // otherwise accordion expands
                      setAddSourceDialogOpen(true);
                    }}
                  >
                    Add Source
                  </Button>
                </RBACWrapper>
                {selectedSources.length > 0 && (
                  <RBACWrapper requiredRole={Role.User}>
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation(); // otherwise accordion expands
                        setOpen(true);
                        setDeletingKind(ResourceKind.SOURCE);
                      }}
                    >
                      Delete {selectedSources.length} Source
                      {selectedSources.length > 1 && "s"}
                    </Button>
                  </RBACWrapper>
                )}
              </Stack>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <NewResourceDialog
              platform={"unknown"}
              kind={Kind.Source}
              resourceTypes={sourcesAndTypes?.sourceTypes ?? []}
              resources={sourcesAndTypes?.sources ?? []}
              open={addSourceDialogOpen}
              onSaveNew={handleSaveNewSource}
              onSaveExisting={() => {}}
              onClose={() => setAddSourceDialogOpen(false)}
              fromLibrary
            />
            <AddToLibraryDialog
              onAdd={addFunc}
              open={addToLibraryDialogOpen}
              onClose={() => setAddToLibraryDialogOpen(false)}
              kind={addKind!}
              existingNames={existingResourceNames ?? []}
            />
            <SourceDataGrid
              sources={sourcesAndTypes?.sources}
              onEditSource={(id: string) => setEditingSource(id)}
              hideFooter
              rowSelectionModel={selectedSources}
              checkboxSelection
              disableRowSelectionOnClick
              onRowSelectionModelChange={(newSelection) =>
                setSelectedSources(newSelection)
              }
            />
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded className={classes([styles.accordion])}>
          <AccordionSummary expandIcon={<ChevronDown />}>
            <Stack
              direction={"row"}
              flexGrow={1}
              justifyContent={"space-between"}
              marginRight={"8px"}
            >
              <Typography variant="h5">Processors</Typography>
              <Stack direction={"row"} spacing={2}>
                <RBACWrapper requiredRole={Role.User}>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={(e) => {
                      e.stopPropagation(); // otherwise accordion expands
                      setAddProcessorDialogOpen(true);
                    }}
                  >
                    Add Processor
                  </Button>
                </RBACWrapper>
                {selectedProcessors.length > 0 && (
                  <RBACWrapper requiredRole={Role.User}>
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation(); // otherwise accordion expands
                        setOpen(true);
                        setDeletingKind(ResourceKind.PROCESSOR);
                      }}
                      classes={{ root: mixins["float-right"] }}
                    >
                      Delete {selectedProcessors.length} Processor
                      {selectedProcessors.length > 1 && "s"}
                    </Button>
                  </RBACWrapper>
                )}
              </Stack>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <NewResourceDialog
              platform={"unknown"}
              kind={Kind.Processor}
              resourceTypes={processorsAndTypes?.processorTypes ?? []}
              resources={processorsAndTypes?.processors ?? []}
              open={addProcessorDialogOpen}
              onSaveNew={handleSaveNewProcessor}
              onSaveExisting={() => {}}
              onClose={() => setAddProcessorDialogOpen(false)}
              fromLibrary
            />
            <ProcessorDataGrid
              processors={processorsAndTypes?.processors}
              onEditProcessor={(id: string) => setEditingProcessor(id)}
              hideFooter
              rowSelectionModel={selectedProcessors}
              checkboxSelection
              disableRowSelectionOnClick
              onRowSelectionModelChange={(newSelection) =>
                setSelectedProcessors(newSelection)
              }
            />
          </AccordionDetails>
        </Accordion>
        <Stack id={"destinations"}>
          <Accordion defaultExpanded className={classes([styles.accordion])}>
            <AccordionSummary expandIcon={<ChevronDown />}>
              <Stack
                direction={"row"}
                flexGrow={1}
                justifyContent={"space-between"}
                marginRight={"8px"}
              >
                <Typography variant="h5">Destinations</Typography>
                <Stack direction={"row"} spacing={2}>
                  <RBACWrapper requiredRole={Role.User}>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={(e) => {
                        e.stopPropagation(); // otherwise accordion expands
                        setAddDestinationDialogOpen(true);
                      }}
                    >
                      Add Destination
                    </Button>
                  </RBACWrapper>
                  {selectedDestinations.length > 0 && (
                    <RBACWrapper requiredRole={Role.User}>
                      <Button
                        size="small"
                        variant="contained"
                        color="error"
                        onClick={(e) => {
                          e.stopPropagation(); // otherwise accordion expands
                          setOpen(true);
                          setDeletingKind(ResourceKind.DESTINATION);
                        }}
                        classes={{ root: mixins["float-right"] }}
                      >
                        Delete {selectedDestinations.length} Destination
                        {selectedDestinations.length > 1 && "s"}
                      </Button>
                    </RBACWrapper>
                  )}
                </Stack>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <NewResourceDialog
                platform={"unknown"}
                kind={Kind.Destination}
                resourceTypes={destinationsAndTypes?.destinationTypes ?? []}
                resources={destinationsAndTypes?.destinations ?? []}
                open={addDestinationDialogOpen}
                onSaveNew={handleSaveNewDestination}
                onSaveExisting={() => {}}
                onClose={() => setAddDestinationDialogOpen(false)}
                fromLibrary
              />
              <DestinationsDataGrid
                columnFields={[
                  DestinationsTableField.NAME,
                  DestinationsTableField.TYPE,
                  DestinationsTableField.CONFIGURATIONS,
                  DestinationsTableField.DATA_OUT,
                ]}
                destinations={destinationsAndTypes?.destinations ?? []}
                configurationMetrics={
                  destinationMetrics?.overviewMetrics?.metrics
                }
                autoHeight
                loading={loadingDestinationsAndTypes}
                allowSelection
                onEditDestination={(name: string) =>
                  setEditingDestination(name)
                }
                hideFooter
                rowSelectionModel={selectedDestinations}
                checkboxSelection
                onRowSelectionModelChange={(newSelection) =>
                  setSelectedDestinations(newSelection)
                }
                setSelectionModel={setSelectedDestinations}
              />
            </AccordionDetails>
          </Accordion>
        </Stack>
        {editingSource && (
          <EditSourceDialog
            name={editingSource}
            onCancel={() => setEditingSource(null)}
            onSaveSuccess={() => setEditingSource(null)}
            readOnly={!hasPermission(Role.Admin, role)}
          />
        )}
        {editingProcessor && (
          <EditProcessorDialog
            name={editingProcessor}
            onCancel={() => setEditingProcessor(null)}
            onSaveSuccess={() => setEditingProcessor(null)}
            readOnly={!hasPermission(Role.Admin, role)}
          />
        )}
        {editingDestination && (
          <EditDestinationDialog
            name={editingDestination}
            onCancel={() => setEditingDestination(null)}
            onSaveSuccess={() => setEditingDestination(null)}
            readOnly={!hasPermission(Role.Admin, role)}
            isLibraryView
          />
        )}
        <ConfirmDeleteResourceDialog
          open={open}
          onClose={() => setOpen(false)}
          onDelete={deleteLibraryResources}
          onCancel={() => setOpen(false)}
          action={"delete"}
        >
          <Typography>
            Are you sure you want to delete {selectedForDeletion.length}{" "}
            {deletingKind}
            {selectedForDeletion.length > 1 && "s"}?
          </Typography>
        </ConfirmDeleteResourceDialog>

        <FailedDeleteDialog
          open={failedDeletesOpen}
          failures={failedDeletes}
          onAcknowledge={onAcknowledge}
          onClose={() => setFailedDeletesOpen(false)}
        />
      </Stack>
    </ValidationContextProvider>
  );
};

export const ResourceLibraryPage = withRequireLogin(
  withRBAC(withEENavBar(ResourceLibraryPageContent)),
);

import { gql, useApolloClient } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Divider,
  Link,
  Menu,
  MenuItem,
  MenuList,
  MenuProps,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  AuthType,
  GetUserInfoQuery,
  useGetFeatureGateQuery,
  useGetUserInfoLazyQuery,
} from "../../graphql/generated";
import { getAuthType } from "../../utils/get-auth-type";
import { AccountSwitcher } from "../AccountSwitcher";
import { BindplaneVersion } from "../BindplaneVersion/BindplaneVersion";
import {
  EmailIcon,
  HelpCircleIcon,
  ListIcon,
  LogoutIcon,
  SlackIcon,
  ToolIcon,
  UsersIcon,
} from "../Icons";
import { getAccountsEnabled } from "./utils";

import styles from "./settings-menu.module.scss";

gql`
  query getUserInfo {
    user {
      metadata {
        id
        name
        version
        displayName
      }
      spec {
        activeAccount
      }
    }
    accounts {
      metadata {
        id
        name
        version
        displayName
      }
    }
  }

  mutation changeAccount($input: ChangeAccountInput!) {
    changeAccount(input: $input) {
      metadata {
        name
        id
        version
        displayName
      }
      spec {
        activeAccount
      }
    }
  }

  query getFeatureGate($input: String!) {
    enabled: featureGate(id: $input)
  }
`;

export const EESettingsMenu: React.FC<MenuProps> = ({
  anchorEl,
  open,
  onClose,
}) => {
  const navigate = useNavigate();
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const accountsEnabled = getAccountsEnabled();
  const { logout } = useAuth0();

  const [getUserInfo, { data: userInfo }] = useGetUserInfoLazyQuery({
    onError: (err) => {
      console.error(err);
      enqueueSnackbar("Error getting user info", {
        variant: "error",
        key: "get-user-info-error",
      });
    },
  });

  const { loading: featureGateLoading, data: featureGateData } =
    useGetFeatureGateQuery({
      variables: {
        input: "audit-trail",
      },
      onError: (err) => {
        console.error(err);
        enqueueSnackbar("Error querying enabled features", {
          variant: "error",
          key: "get-feature-gate-error",
        });
      },
    });

  useEffect(() => {
    // Get user and account info if accounts are enabled.
    if (accountsEnabled) {
      getUserInfo();
    }
  }, [getUserInfo, accountsEnabled]);

  const currentAccount: GetUserInfoQuery["accounts"][0] | undefined =
    useMemo(() => {
      if (
        userInfo != null &&
        userInfo.accounts != null &&
        userInfo.user != null
      ) {
        return userInfo.accounts.find(
          (a) => a.metadata.id === userInfo.user?.spec.activeAccount,
        );
      }
    }, [userInfo]);

  async function handleLogout() {
    try {
      localStorage.removeItem("user");
      localStorage.removeItem("auth0User");
      if (getAuthType() === AuthType.Auth0) {
        await logout({ openUrl: false });
      }
      await apolloClient.resetStore();
      await fetch("/logout", {
        method: "PUT",
      });
      navigate("/login");
    } catch (err) {
      console.error(err);
    }
  }
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      style={{
        left: "1.475rem",
      }}
      slotProps={{
        paper: {
          className: styles["settings-paper"],
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      MenuListProps={{
        "aria-labelledby": "settings-button",
      }}
      classes={{ paper: styles.menu }}
    >
      {
        // If accounts are enabled, show the account switcher and info.
        accountsEnabled && (
          <div>
            <AccountSwitcher
              accounts={userInfo?.accounts}
              activeAccountId={userInfo?.user?.spec.activeAccount}
            />
            <Stack className={styles["info-stack"]} marginBottom={1}>
              <Typography variant="h6">
                {currentAccount?.metadata?.displayName}
              </Typography>
              <Typography>{userInfo?.user?.metadata.displayName}</Typography>
            </Stack>
            <Divider />
          </div>
        )
      }

      <MenuList>
        {accountsEnabled && (
          <MenuItem onClick={() => navigate("/account")}>
            <UsersIcon className={styles["settings-icon"]} />
            Account
          </MenuItem>
        )}
        {!featureGateLoading && featureGateData?.enabled && (
          <MenuItem onClick={() => navigate("/audit-logs")}>
            <ListIcon className={styles["settings-icon"]} />
            Audit Logs
          </MenuItem>
        )}
        <Divider />
        <MenuItem
          component={Link}
          data-testid="slack-link"
          href="https://observiq.com/support-bindplaneop/"
          underline="none"
          color="inherit"
          variant="inherit"
          target="_blank"
          rel="noopener"
        >
          <Stack direction={"row"}>
            <SlackIcon className={styles["settings-icon"]} />
            <Typography>Slack Community</Typography>
          </Stack>
        </MenuItem>
        <MenuItem
          component={Link}
          data-testid="support-link"
          href="mailto:support.bindplaneop@observiq.com"
          underline="none"
          color="inherit"
          variant="inherit"
          target="_blank"
          rel="noopener"
        >
          <Stack direction={"row"}>
            <EmailIcon className={styles["settings-icon"]} />
            <Typography>Email Support</Typography>
          </Stack>
        </MenuItem>
        <MenuItem
          component={Link}
          data-testid="doc-link"
          href="https://observiq.com/docs/getting-started/quickstart-guide"
          underline="none"
          color="inherit"
          variant="inherit"
          target="_blank"
          rel="noopener"
        >
          <Stack direction={"row"}>
            <HelpCircleIcon className={styles["settings-icon"]} />
            <Typography>Documentation</Typography>
          </Stack>
        </MenuItem>
        <MenuItem
          component={Link}
          data-testid="api-documentation-link"
          href="/swagger"
          underline="none"
          color="inherit"
          variant="inherit"
          target="_blank"
          rel="noopener"
        >
          <Stack direction={"row"}>
            <ToolIcon className={styles["settings-icon"]} />
            <Typography>API Documentation</Typography>
          </Stack>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <LogoutIcon className={styles["settings-icon"]} />
          Logout
        </MenuItem>
        <Divider />
        <BindplaneVersion />
      </MenuList>
    </Menu>
  );
};

import { gql } from "@apollo/client";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  AuthType,
  GetAccountPageInfoQuery,
  Role,
  useGetInvitationsQuery,
  useInvitationsSubscription,
} from "../../../graphql/generated";
import { getAuthType } from "../../../utils/get-auth-type";

import { BusinessIcon, PlusCircleIcon } from "../../../components/Icons";

import { InvitationsTable } from "../../../components/InvitationsTable";
import { InviteUserDialog } from "../../../components/InviteUserDialog";
import { RBACWrapper } from "../../../contexts/RBAC";
import styles from "./account-page.module.scss";

gql`
  query getInvitations {
    invitations {
      id
      accountId
      email
      createdAt
      expiredAt
      redeemedAt
      createdBy
      redeemedBy
      role
      note
      status
      link
    }
  }

  subscription invitations {
    invitations {
      id
    }
  }
`;
interface AccountTabProps {
  account?: GetAccountPageInfoQuery["accounts"][0];
}

export const AccountTab: React.FC<AccountTabProps> = ({ account }) => {
  const [addUserOpen, setAddUserOpen] = useState(false);

  const { data, loading, refetch } = useGetInvitationsQuery({
    fetchPolicy: "network-only",
  });

  useInvitationsSubscription({
    onData: () => {
      refetch();
    },
  });

  function handleAddUserClick() {
    setAddUserOpen(true);
  }

  function handleCloseAddUserDialog() {
    setAddUserOpen(false);
  }

  const showInvitations =
    getAuthType() === AuthType.Google ||
    getAuthType() === AuthType.Ldap ||
    getAuthType() === AuthType.Auth0;

  return (
    <>
      <Typography className={styles.header} marginBottom={2}>
        Account Information
      </Typography>
      <Card>
        <CardContent>
          <Stack direction="row" spacing={2}>
            <Avatar classes={{ root: styles.avatar }}>
              <BusinessIcon />
            </Avatar>

            <Stack>
              <Typography fontWeight={600}>
                {account?.metadata.displayName}
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
      </Card>

      {showInvitations && (
        <>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
            marginY={1}
          >
            <Typography className={styles.header} marginY={2}>
              Invitations
            </Typography>

            <RBACWrapper requiredRole={Role.Admin}>
              <Button
                variant="contained"
                startIcon={<PlusCircleIcon />}
                onClick={handleAddUserClick}
              >
                Invite Users
              </Button>
            </RBACWrapper>
          </Stack>

          <InvitationsTable data={data} loading={loading} />

          <InviteUserDialog
            open={addUserOpen}
            onClose={handleCloseAddUserDialog}
            onCancel={handleCloseAddUserDialog}
          />
        </>
      )}
    </>
  );
};

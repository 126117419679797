import {
  Autocomplete,
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
  Popper,
  PopperProps,
} from "@mui/material";
import React, { memo, useRef, useState } from "react";
import { Suggestion } from "../../graphql/generated";
import { SearchInput } from "./input";
import { validateStringAsBPLabelKey } from "../../utils/forms/validate-name-field";

import styles from "./search-bar.module.scss";
import mixins from "../../styles/mixins.module.scss";

type AutocompleteValue = Suggestion | string;

const AUTOCOMPLETE_CLASSES = {
  root: mixins["mb-1"],
  listbox: styles.listbox,
  popper: styles.popper,
  option: styles.option,
  paper: styles.paper,
};

interface SearchBarProps {
  suggestions?: Suggestion[] | null;
  suggestionQuery?: string | null;
  initialQuery?: string;
  filterOptions?: Suggestion[];
  placeholder?: string;

  // callback called when the value of the autocomplete component changes, typically on enter
  onQueryChange: (v: string) => void;

  // If true, the search bar will clear the search bar when a valid key:value is selected
  clearOnValueSelect?: boolean;
  // callback called when a valid key:value is selected
  onValueSelect?: (v: string) => void;
  // if true, the search bar will only allow one token to be entered, i.e. no spaces
  limitOneToken?: boolean;

  // if true, the search bar will display an error state if the value entered
  // onAutocompleteChange is not a valid BindPlane OP label.
  validateAsLabel?: boolean;

  autofocus?: boolean;
}

const SearchBarComponent: React.FC<SearchBarProps> = ({
  filterOptions,
  suggestions,
  suggestionQuery,
  initialQuery,
  onQueryChange,
  placeholder,
  clearOnValueSelect,
  onValueSelect,
  limitOneToken,
  validateAsLabel,
  autofocus,
}) => {
  const popperElRef = useRef<HTMLSpanElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [query, setQuery] = useState(initialQuery ?? "");
  const [error, setError] = useState(false);

  function handleInputChange(
    _e: React.SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) {
    if (reason === "input") {
      if (error) {
        setError(false);
      }

      if (value.endsWith(" ") && limitOneToken) {
        return;
      }
      onQueryChange(value);
      setQuery(value);
    }
  }

  function handleAutocompleteChange(
    _e: React.SyntheticEvent,
    suggestion: AutocompleteValue,
  ) {
    const value =
      typeof suggestion === "string" ? suggestion : suggestion.query;
    onQueryChange(value);

    const isValueSelect = stringIsKeyValue(value);
    if (isValueSelect && onValueSelect) {
      const trimmedValue = value.trim();
      if (validateAsLabel && !isValidLabel(trimmedValue)) {
        setError(true);
        return;
      }

      onValueSelect(trimmedValue);
    }

    if (isValueSelect && clearOnValueSelect) {
      setQuery("");
      setError(false);
      return;
    }

    setQuery(value);
  }

  function handleFilterClick(query: string) {
    setQuery(query);
    onQueryChange(query);
  }

  function renderSearchInput(params: AutocompleteRenderInputParams) {
    return (
      <SearchInput
        filterOptions={filterOptions}
        inputValue={query}
        popperElRef={popperElRef}
        onFilterClick={handleFilterClick}
        placeholder={placeholder}
        error={error}
        autofocus={autofocus}
        {...params}
      />
    );
  }

  function renderPopper(props: PopperProps) {
    // It's unclear why, but we need to override the style here
    // for the popper to position correctly.
    return <Popper {...props} style={{}} anchorEl={popperElRef.current} />;
  }

  const filteredSuggestions = relevantSuggestions(
    query,
    suggestions,
    suggestionQuery,
  );

  return (
    <Autocomplete
      classes={AUTOCOMPLETE_CLASSES}
      onClose={(_e: React.SyntheticEvent, reason) => {
        if (reason === "selectOption") {
          return;
        }
        setOpen(false);
      }}
      onOpen={() => setOpen(true)}
      open={open}
      options={filteredSuggestions}
      autoHighlight
      inputValue={query}
      size="small"
      getOptionLabel={(s: Suggestion | string) => {
        if (typeof s === "object") {
          return s.label;
        } else {
          return s;
        }
      }}
      freeSolo
      disableClearable
      // Overrides the autocomplete behavior
      filterOptions={(x) => x}
      onInputChange={handleInputChange}
      onChange={handleAutocompleteChange}
      renderInput={renderSearchInput}
      PopperComponent={renderPopper}
    />
  );
};

/**
 * Relevant suggestions returns the suggestions only if they are not null
 * and the given query matches the suggestionQuery
 */
export function relevantSuggestions(
  query: string,
  suggestions?: Suggestion[] | null,
  suggestionQuery?: string | null,
): Suggestion[] {
  if (query !== suggestionQuery || suggestions == null) return [];
  return suggestions.filter((s) => s.label !== "");
}

export const SearchBar = memo(SearchBarComponent);

/**
 * stringIsKeyValue checks to see if the string has a key and field,
 * i.e. that it has a string value after the colon.
 *
 * @param str the value to check
 */
function stringIsKeyValue(str: string): boolean {
  const [, value] = str.split(":");
  return !!value;
}

function isValidLabel(str: string): boolean {
  const [key, value] = str.split(":");
  if (key === "" || value === "") {
    return false;
  }

  if (
    validateStringAsBPLabelKey(key) !== undefined ||
    validateStringAsBPLabelKey(value) !== undefined
  ) {
    return false;
  }

  return true;
}

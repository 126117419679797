import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import {
  Kind,
  useConfigurationCountQuery,
  useGetSourceWithTypeQuery,
} from "../../../graphql/generated";
import { BPSource } from "../../../utils/classes";
import { EditResourceDialog } from "../../ResourceDialog/EditResourceDialog";
import { EditResourceInUseWarningDialog } from "../../Dialogs/EditResourceInUseWarningDialog/EditResourceInUseWarningDialog";
import { trimVersion } from "../../../utils/version-helpers";

interface EditSourceProps {
  name: string;
  onSaveSuccess: () => void;
  onCancel: () => void;
  readOnly?: boolean;
  showLibraryBookmark?: boolean;
}

export const EditSourceDialog: React.FC<EditSourceProps> = ({
  name,
  readOnly,
  onSaveSuccess,
  onCancel,
  showLibraryBookmark,
}) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [
    editResourceInUseWarningDialogOpen,
    setEditResourceInUseWarningDialogOpen,
  ] = useState<{
    open: boolean;
    values: { [key: string]: any };
    function: "onSave" | "onToggle" | null;
  }>({
    open: false,
    values: {},
    function: null,
  });

  const { data, error } = useGetSourceWithTypeQuery({
    variables: {
      name,
    },
    fetchPolicy: "network-only",
  });

  // Communicate error if present.
  useEffect(() => {
    if (error != null) {
      enqueueSnackbar(`Error retrieving data for source ${name}.`, {
        variant: "error",
      });
      console.error(error);
    }
  }, [enqueueSnackbar, error, name]);

  const { data: configurationCount } = useConfigurationCountQuery({
    variables: {
      query: `${Kind.Source}:${trimVersion(name)}`,
    },
    fetchPolicy: "network-only",
    onError(error) {
      console.error(error);
      enqueueSnackbar(
        `Failed to get configuration count for ${Kind.Source} ${trimVersion(
          name,
        )}`,
        {
          variant: "error",
        },
      );
    },
  });

  // Open dialog when we have data.
  useEffect(() => {
    if (data != null && configurationCount != null) {
      setOpen(true);
    }
  }, [data, configurationCount, setOpen]);

  function interceptSave(values: { [key: string]: any }) {
    if (data?.sourceWithType?.source == null) {
      enqueueSnackbar("Cannot save source.", { variant: "error" });
      console.error("no source found when requesting source and type");
      return;
    }
    if (configurationCount?.configurationCount == null) {
      enqueueSnackbar("Cannot get configuration count.", { variant: "error" });
      console.error("Cannot get configuration count");
      return;
    }
    if (configurationCount.configurationCount > 1) {
      setEditResourceInUseWarningDialogOpen({
        open: true,
        values: values,
        function: "onSave",
      });
    } else {
      handleSave(values);
    }
  }

  async function handleSave(values: { [key: string]: any }) {
    setEditResourceInUseWarningDialogOpen({
      open: false,
      values: {},
      function: null,
    });

    const source = new BPSource(data?.sourceWithType.source!);
    source.setParamsFromMap(values);

    source.metadata.displayName = values.displayName;

    try {
      await source.apply();
      enqueueSnackbar("Saved source!", { variant: "success" });
      setOpen(false);
      onSaveSuccess();
    } catch (err) {
      enqueueSnackbar("Error saving source", { variant: "error" });
      console.error(err);
    }
  }

  function handleClose() {
    setOpen(false);
    onCancel();
  }

  function handleWarnConfirm() {
    if (editResourceInUseWarningDialogOpen.function === "onSave") {
      handleSave(editResourceInUseWarningDialogOpen.values);
      // } else if (editResourceInUseWarningDialogOpen.function === "onToggle") {
      // onTogglePause(); future addition
    } else {
      console.error("Provided function for warn dialog confirm is null");
    }
  }

  return (
    <>
      <EditResourceDialog
        fullWidth
        resourceTypeDisplayName={
          data?.sourceWithType?.sourceType?.metadata?.displayName ?? ""
        }
        resourceNameField={data?.sourceWithType?.source?.metadata?.name ?? ""}
        displayName={data?.sourceWithType.source?.metadata.displayName ?? ""}
        description={
          data?.sourceWithType.sourceType?.metadata.description ?? ""
        }
        onSave={interceptSave}
        onClose={handleClose}
        onCancel={handleClose}
        additionalInfo={
          data?.sourceWithType.sourceType?.metadata.additionalInfo
        }
        resourceDocLink={
          data?.sourceWithType.sourceType?.metadata.resourceDocLink ?? ""
        }
        parameters={data?.sourceWithType.source?.spec.parameters ?? []}
        parameterDefinitions={
          data?.sourceWithType.sourceType?.spec.parameters ?? []
        }
        paused={data?.sourceWithType.source?.spec.disabled}
        kind={Kind.Source}
        open={open}
        readOnly={readOnly}
        showLibraryBookmark={showLibraryBookmark}
      />
      <EditResourceInUseWarningDialog
        resourceName={data?.sourceWithType?.source?.metadata.name ?? ""}
        resourceKind={Kind.Source}
        configurationCount={configurationCount?.configurationCount!}
        open={editResourceInUseWarningDialogOpen.open}
        onCancel={() =>
          setEditResourceInUseWarningDialogOpen({
            open: false,
            values: {},
            function: null,
          })
        }
        onConfirm={() => handleWarnConfirm()}
      />
    </>
  );
};

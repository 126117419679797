import { Card, Stack, TableRow, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import { isArray, isObject } from "lodash";
import { useMemo, useRef, useState } from "react";
import { Log, PipelineType } from "../../../graphql/generated";
import { SnapshotRegion } from "../regions";
import { getTimestamp } from "../utils";
import { CellLabel, CellValue } from "./Cells";
import { DetailsContainer } from "./DetailsContainer";
import { MapValueSummary } from "./MapValueSummary";
import { RowSummary } from "./RowSummary";
import { AttributesProvider } from "./SnapShotRow";
import { SummaryTable } from "./SummaryTable";

import styles from "../snap-shot-console.module.scss";
import { useSnapshot } from "../SnapshotContext";
import { highlightSearchQuery, useWatchForOpen } from "./utils";

interface LogRecordRowProps {
  filtered?: boolean;
  message: Log;
  attributes: AttributesProvider;
  bindplaneID: string;
}

export const LogRecordRow: React.FC<LogRecordRowProps> = ({
  filtered,
  message,
  attributes,
  bindplaneID,
}) => {
  const timestamp = useMemo(
    () => getTimestamp(message, PipelineType.Logs),
    [message],
  );

  const severity = useMemo(() => {
    switch (message.severity) {
      case "trace":
        return message.severity;
      case "debug":
        return "debug";
      case "info":
        return "info";
      case "warning":
        return "warning";
      case "error":
        return "error";
      case "fatal":
        return "fatal";
      default:
        return "default";
    }
  }, [message]);

  const { searchRegex } = useSnapshot();
  // formatting the body as JSON is a bit arbitrary. A structured log doesn't necessarily
  // originate as JSON, but there is no representation of the original format available.
  const body = highlightSearchQuery(
    JSON.stringify(message.body, undefined, 2),
    searchRegex,
  );

  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  useWatchForOpen(ref, setOpen);

  return (
    <Card
      classes={{ root: styles.card }}
      data-region={SnapshotRegion.ROW}
      data-row-id={bindplaneID}
      ref={ref}
    >
      <RowSummary
        filtered={filtered}
        bindplaneID={bindplaneID}
        timestamp={timestamp}
        data-region={SnapshotRegion.ROW_SUMMARY}
      >
        <Stack direction="row" spacing={2} alignItems="center" width={"100%"}>
          <Chip
            label={severity}
            size={"small"}
            color={severity}
            data-region={SnapshotRegion.SEVERITY}
            data-severity={severity}
            className={styles.severity}
          />

          <Typography
            fontFamily="monospace"
            fontSize={12}
            overflow={"hidden"}
            textOverflow="ellipsis"
          >
            {body}
          </Typography>
        </Stack>
      </RowSummary>

      {open && (
        <DetailsContainer>
          <Typography fontWeight={600}>Log</Typography>

          <SummaryTable>
            <TableRow>
              <CellLabel>timestamp</CellLabel>
              <CellValue>{timestamp}</CellValue>
            </TableRow>

            <TableRow>
              <CellLabel>severity</CellLabel>
              <CellValue>{severity}</CellValue>
            </TableRow>
          </SummaryTable>

          <Typography fontWeight={600} marginTop={2}>
            Body
          </Typography>
          <MapValueSummary
            value={flattenBodyFields(message.body)}
            fieldType="body"
            emptyMessage="No body values"
          />

          <Typography fontWeight={600} marginTop={2}>
            Attributes
          </Typography>
          <MapValueSummary
            value={attributes()}
            fieldType="attribute"
            emptyMessage="No attribute values"
          />

          <Typography fontWeight={600} marginTop={2}>
            Resource
          </Typography>
          <MapValueSummary
            value={message.resource}
            fieldType="resource"
            emptyMessage="No resource values"
          />
        </DetailsContainer>
      )}
    </Card>
  );
};

// flattenBodyFields takes a nested object and flattens it into a single level, using OTTL
// syntax for nested fields.
export function flattenBodyFields(
  fields: any,
  prefix = "",
): Record<string, any> {
  const flattened: Record<string, any> = {};

  // if it's not an object, just return it as is
  if (isArray(fields)) {
    // use [i] syntax for each element
    for (let i = 0; i < fields.length; i++) {
      Object.assign(flattened, flattenBodyFields(fields[i], `${prefix}[${i}]`));
    }
  } else if (isObject(fields)) {
    // use ["key"] syntax for each key
    for (const [key, value] of Object.entries(fields)) {
      // recurse with this name as the prefix
      Object.assign(
        flattened,
        flattenBodyFields(value, prefix === "" ? key : `${prefix}["${key}"]`),
      );
    }
  } else {
    // handle the case of a simple string
    if (prefix === "") {
      return fields;
    }
    // normal value
    flattened[prefix] = fields;
  }

  return flattened;
}

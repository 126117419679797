import { Dialog, Stack } from "@mui/material";
import { gql } from "@apollo/client";
import { isEqual } from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import React from "react";
import {
  Kind,
  PipelineType,
  ResourceConfiguration,
  ResourceTypeKind,
  Role,
  useProcessorDialogDestinationTypeLazyQuery,
  useProcessorDialogSourceTypeLazyQuery,
  useUpdateProcessorsMutation,
  useProcessorsWithParamsQuery,
  ProcessorsWithParamsQuery,
  useGetResourceWithTypeQuery,
} from "../../graphql/generated";
import { trimVersion } from "../../utils/version-helpers";
import { usePipelineGraph } from "../PipelineGraph/PipelineGraphContext";
import { SnapshotConsole } from "../SnapShotConsole/SnapShotConsole";
import { ResourceDialogContextProvider } from "../ResourceDialog/ResourceDialogContext";
import {
  SnapshotContextProvider,
  useSnapshot,
} from "../SnapShotConsole/SnapshotContext";
import { hasPermission } from "../../utils/has-permission";
import { useRole } from "../../hooks/useRole";
import { TitleSection } from "../DialogComponents";
import { ResourceConfigurationEditor } from "../ResourceConfigurationEditor";

import { ProcessorTelemetrySwitcher } from "../ProcessorTelemetrySwitcher/ProcessorTelemetrySwitcher";
import { ProcessorDialogProps } from "../EEProcessorDialog/EEProcessorDialog";
import { SnapShotControls } from "../SnapShotConsole/SnapShotControls";

import styles from "./processor-dialog.module.scss";

gql`
  query ProcessorsWithParams {
    processors {
      metadata {
        id
        version
        name
      }
      spec {
        type
        parameters {
          name
          value
        }
      }
    }
  }
`;

export type ReusableProcessors = ProcessorsWithParamsQuery["processors"];

// This is the higher-order component
export function withCommonProcessorDialog(
  DialogComponent: React.FC<ProcessorDialogProps>,
): React.FC {
  return (props) => {
    const role = useRole();
    const {
      editProcessorsInfo,
      configuration,
      editProcessorsOpen,
      closeProcessorDialog,
      readOnlyGraph,
    } = usePipelineGraph();

    // This data is used by the ChooseView component for reusable processors (and extensions).
    // Putting this fetch here shares it between the EE and non-EE processor dialog.
    const {
      data: reusableProcessorsData,
      error: fetchReusableProcessorsDataError,
      refetch: refetchReusableProcessorsData,
    } = useProcessorsWithParamsQuery({ fetchPolicy: "cache-and-network" });

    if (editProcessorsInfo === null) {
      return null;
    }

    let processors: ResourceConfiguration[] = [];
    switch (editProcessorsInfo?.resourceType) {
      case "source":
        processors =
          configuration?.spec?.sources?.[editProcessorsInfo.index].processors ??
          [];
        break;
      case "destination":
        processors =
          configuration?.spec?.destinations?.[editProcessorsInfo.index]
            .processors ?? [];
        break;
      default:
        processors = [];
    }

    return (
      <DialogComponent
        open={editProcessorsOpen}
        onClose={closeProcessorDialog}
        processors={processors}
        reusableProcessors={reusableProcessorsData?.processors}
        fetchReusableProcessorsError={fetchReusableProcessorsDataError}
        refetchReusableProcessors={refetchReusableProcessorsData}
        readOnly={readOnlyGraph || !hasPermission(Role.User, role)}
        {...props}
      />
    );
  };
}

export const ProcessorDialogComponent: React.FC<ProcessorDialogProps> = ({
  processors: processorsProp,
  reusableProcessors: reusableProcessorsProp,
  fetchReusableProcessorsError,
  refetchReusableProcessors,
  readOnly,
  ...dialogProps
}) => {
  const {
    editProcessorsInfo,
    configuration,
    closeProcessorDialog,
    refetchConfiguration,
    selectedTelemetryType,
  } = usePipelineGraph();

  const [processors, setProcessors] = useState(processorsProp);
  const { enqueueSnackbar } = useSnackbar();

  const resourceNameField = useMemo(() => {
    try {
      switch (editProcessorsInfo?.resourceType) {
        case "source":
          return configuration?.spec?.sources?.[editProcessorsInfo.index].name;
        case "destination":
          return configuration?.spec?.destinations?.[editProcessorsInfo.index]
            .name;
        default:
          return null;
      }
    } catch (err) {
      return null;
    }
  }, [configuration, editProcessorsInfo]);

  // Get the type of the source or destination to which we're adding processors
  const resourceTypeName = useMemo(() => {
    try {
      switch (editProcessorsInfo?.resourceType) {
        case "source":
          return configuration?.spec?.sources?.[editProcessorsInfo.index].type;
        case "destination":
          return configuration?.spec?.destinations?.[editProcessorsInfo.index]
            .type;
        default:
          return null;
      }
    } catch (err) {
      return null;
    }
  }, [
    configuration?.spec?.destinations,
    configuration?.spec?.sources,
    editProcessorsInfo?.index,
    editProcessorsInfo?.resourceType,
  ]);

  /* ------------------------ GQL Mutations and Queries ----------------------- */
  const [updateProcessors] = useUpdateProcessorsMutation({});

  const [fetchSourceType, { data: sourceTypeData }] =
    useProcessorDialogSourceTypeLazyQuery({
      variables: { name: resourceTypeName ?? "" },
    });
  const [fetchDestinationType, { data: destinationTypeData }] =
    useProcessorDialogDestinationTypeLazyQuery();

  const { data: reusableSource, refetch: refetchReusableSource } =
    useGetResourceWithTypeQuery({
      skip: !resourceNameField,
      variables: {
        kind: Kind.Source,
        name: resourceNameField ?? "",
      },
      onError(error) {
        console.error(error);
        enqueueSnackbar("Failed to get source for processors.", {
          variant: "error",
          autoHideDuration: 5000,
        });
      },
    });

  useEffect(() => {
    function fetchData() {
      refetchReusableSource();
      if (editProcessorsInfo!.resourceType === "source") {
        fetchSourceType({ variables: { name: resourceTypeName ?? "" } });
      } else {
        const destName =
          configuration?.spec?.destinations?.[editProcessorsInfo!.index].name;
        fetchDestinationType({ variables: { name: destName ?? "" } });
      }
    }

    if (editProcessorsInfo == null) {
      return;
    }

    fetchData();
  }, [
    configuration?.spec?.destinations,
    editProcessorsInfo,
    fetchDestinationType,
    fetchSourceType,
    resourceTypeName,
    refetchReusableSource,
  ]);

  /* -------------------------------- Functions ------------------------------- */

  // handleClose is called when a user clicks off the dialog or the "X" button
  function handleClose() {
    if (!isEqual(processors, processorsProp)) {
      const ok = window.confirm("Discard changes?");
      if (!ok) {
        return;
      }
      // reset form values if chooses to discard.
      setProcessors(processorsProp);
    }

    closeProcessorDialog();
  }

  async function handleUpdateInlineProcessors(
    processors: ResourceConfiguration[],
  ): Promise<boolean> {
    let success = true;
    await updateProcessors({
      variables: {
        input: {
          configuration: configuration?.metadata?.name!,
          resourceType:
            editProcessorsInfo?.resourceType === "source"
              ? ResourceTypeKind.Source
              : ResourceTypeKind.Destination,
          resourceIndex: editProcessorsInfo?.index!,
          processors: processors,
        },
      },
      onError(error) {
        success = false;
        console.error(error);
        enqueueSnackbar("Failed to save processors", {
          variant: "error",
          key: "save-processors-error",
        });
      },
    });

    return success;
  }

  // displayName for sources is the sourceType name, for destinations it's the destination name
  const { resourceName, displayName } = useMemo(() => {
    if (editProcessorsInfo == null) {
      return {
        resourceName: "",
        displayName: "",
      };
    }
    if (editProcessorsInfo.resourceType === "source") {
      const id = configuration?.spec?.sources?.[editProcessorsInfo.index].id;
      const resourceName = `source${editProcessorsInfo.index}_${id}`;
      return {
        resourceName: resourceName,
        displayName: resourceNameField
          ? resourceNameField
          : sourceTypeData?.sourceType?.metadata.displayName,
      };
    }
    const name =
      configuration?.spec?.destinations?.[editProcessorsInfo.index].name;
    if (name) {
      const trimName = trimVersion(name);
      return {
        resourceName: `${trimName}-${editProcessorsInfo.index}`,
        displayName: trimName,
      };
    }
    return {
      resourceName: `dest${editProcessorsInfo.index}`,
      displayName: `dest${editProcessorsInfo.index}`,
    };
  }, [
    configuration?.spec?.destinations,
    configuration?.spec?.sources,
    editProcessorsInfo,
    sourceTypeData?.sourceType?.metadata.displayName,
    resourceNameField,
  ]);

  const parentDisplayName = displayName ?? "unknown";
  const title = useMemo(() => {
    const kind =
      editProcessorsInfo?.resourceType === "source" ? "Source" : "Destination";
    return `${kind} ${trimVersion(parentDisplayName)}: Processors`;
  }, [editProcessorsInfo?.resourceType, parentDisplayName]);

  const description =
    "Processors are run on data after it's received and prior to being sent to a destination. They will be executed in the order they appear below.";

  const snapshotPosition =
    editProcessorsInfo?.resourceType === "source" ? "s0" : "d0";

  let telemetryTypes: PipelineType[];
  if (editProcessorsInfo?.resourceType === "source") {
    if (resourceNameField) {
      telemetryTypes =
        reusableSource?.resourceWithType.resourceType?.spec.telemetryTypes ??
        [];
    } else {
      telemetryTypes = sourceTypeData?.sourceType?.spec?.telemetryTypes ?? [];
    }
  } else {
    telemetryTypes =
      destinationTypeData?.destinationWithType.destinationType?.spec
        ?.telemetryTypes ?? [];
  }

  return (
    <ResourceDialogContextProvider onClose={handleClose} purpose={"edit"}>
      <SnapshotContextProvider
        pipelineType={convertTelemetryType(selectedTelemetryType)}
        position={snapshotPosition}
        resourceName={resourceName}
        showAgentSelector={true}
      >
        <Dialog
          {...dialogProps}
          maxWidth={"xl"}
          fullWidth
          onClose={handleClose}
          classes={{
            root: styles.dialog,
            paper: styles.paper,
          }}
        >
          <Stack height="calc(100vh - 100px)" minHeight="800px">
            <TitleSection
              title={title}
              description={description}
              onClose={handleClose}
            />
            <SnapshotSection readOnly={readOnly ?? false}>
              <ResourceConfigurationEditor
                initItems={processorsProp}
                items={processors}
                onItemsChange={setProcessors}
                telemetryTypes={telemetryTypes}
                kind={Kind.Processor}
                refetchConfiguration={refetchConfiguration}
                closeDialog={closeProcessorDialog}
                updateInlineItems={handleUpdateInlineProcessors}
                readOnly={readOnly}
              />
            </SnapshotSection>
          </Stack>
        </Dialog>
      </SnapshotContextProvider>
    </ResourceDialogContextProvider>
  );
};

function convertTelemetryType(telemetryType: string): PipelineType {
  switch (telemetryType) {
    case PipelineType.Logs:
      return PipelineType.Logs;
    case PipelineType.Metrics:
      return PipelineType.Metrics;
    case PipelineType.Traces:
      return PipelineType.Traces;
    default:
      return PipelineType.Logs;
  }
}

export const SnapshotSection: React.FC<{
  readOnly: boolean;
}> = ({ children, readOnly }) => {
  const { logs, metrics, traces, footer } = useSnapshot();
  const [workingQuery, setWorkingQuery] = useState<string>("");

  return (
    <Stack
      direction="row"
      width="100%"
      height="calc(100vh - 175px)"
      minHeight={"700px"}
      spacing={2}
      padding={2}
    >
      <div className={styles["snapshot-container"]}>
        <Stack spacing={2} height="100%">
          <SnapShotControls
            workingQuery={workingQuery}
            setWorkingQuery={setWorkingQuery}
          />
          <SnapshotConsole
            logs={logs}
            metrics={metrics}
            traces={traces}
            footer={footer}
            readOnly={readOnly}
          />
        </Stack>
      </div>
      <Stack flexGrow={0} height="100%" spacing={2}>
        <ProcessorTelemetrySwitcher setWorkingQuery={setWorkingQuery} />

        <div className={styles["form-container"]}>{children}</div>
      </Stack>
    </Stack>
  );
};

export const ProcessorDialog = withCommonProcessorDialog(
  ProcessorDialogComponent,
);

import { Chip, Box, Popper, Card, CardContent } from "@mui/material";
import { AGENT_LABEL_INSTALL_ID } from "../../pages/agents/InstallPage/InstallWizard/utils";

import styles from "./label-with-tooltip.module.scss";
import mixins from "../../styles/mixins.module.scss";
import { useRef, useState } from "react";

interface LabelsWithTooltipProps {
  labels: Record<string, string> | undefined;
}

function isOverflown(element: Element): boolean {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

export const LabelsWithTooltip: React.FC<LabelsWithTooltipProps> = ({
  labels,
}) => {
  const cellValue = useRef<HTMLDivElement | null>(null);
  const [tooltipAnchor, setTooltipAnchor] = useState<HTMLDivElement | null>();
  const [showTooltip, setShowTooltip] = useState(false);

  if (labels == null) return <></>;

  if (labels[AGENT_LABEL_INSTALL_ID]) {
    delete labels[AGENT_LABEL_INSTALL_ID];
  }
  if (labels["configuration"]) {
    delete labels["configuration"];
  }
  if (labels["container-platform"]) {
    delete labels["container-platform"];
  }

  const handleMouseEnter = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    const isCurrentlyOverflown = isOverflown(e.currentTarget);
    setShowTooltip(isCurrentlyOverflown);
    setTooltipAnchor(e.currentTarget);
  };

  const filtered = Object.entries(labels)
    .filter(([key, _]) => !key.startsWith("bindplane/agent"))
    .sort((a, b) => {
      const aJoined = a[0] + a[1];
      const bJoined = b[0] + b[1];
      return aJoined.toLowerCase().localeCompare(bJoined.toLowerCase());
    });

  return (
    <>
      <Popper
        open={showTooltip && Boolean(tooltipAnchor)}
        anchorEl={tooltipAnchor}
      >
        <Card classes={{ root: styles.tooltip }}>
          <CardContent sx={{ marginBottom: "-0.5rem" }}>
            {filtered.map(([k, v]) => {
              const formattedLabel = `${k}: ${v}`;
              return (
                <Chip
                  key={k}
                  size="small"
                  label={formattedLabel}
                  classes={{ root: mixins["m-1"] }}
                />
              );
            })}
          </CardContent>
        </Card>
      </Popper>
      <Box
        onMouseEnter={(e) => {
          handleMouseEnter(e);
        }}
        onMouseLeave={() => {
          setTooltipAnchor(null);
        }}
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {filtered.map(([k, v]) => {
          const formattedLabel = `${k}: ${v}`;
          return (
            <Chip
              key={k}
              size="small"
              label={formattedLabel}
              classes={{ root: mixins["m-1"] }}
            />
          );
        })}
      </Box>
    </>
  );
};

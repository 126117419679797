import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import {
  Kind,
  useConfigurationCountQuery,
  useGetProcessorWithTypeQuery,
} from "../../../graphql/generated";
import { BPProcessor } from "../../../utils/classes";
import { EditResourceDialog } from "../../ResourceDialog/EditResourceDialog";
import { gql } from "@apollo/client";
import { EditResourceInUseWarningDialog } from "../../Dialogs/EditResourceInUseWarningDialog/EditResourceInUseWarningDialog";
import { trimVersion } from "../../../utils/version-helpers";

gql`
  query getProcessorWithType($name: String!) {
    processorWithType(name: $name) {
      processor {
        metadata {
          name
          version
          id
          labels
          version
          displayName
        }
        spec {
          type
          parameters {
            name
            value
          }
          disabled
        }
      }
      processorType {
        metadata {
          id
          name
          displayName
          version
          icon
          description
          additionalInfo {
            message
            documentation {
              text
              url
            }
          }
          resourceDocLink
        }
        spec {
          parameters {
            label
            name
            description
            required
            type
            default
            relevantIf {
              name
              operator
              value
            }
            documentation {
              text
              url
            }
            advancedConfig
            validValues
            options {
              multiline
              creatable
              trackUnchecked
              sectionHeader
              subHeader
              horizontalDivider
              gridColumns
              labels
              metricCategories {
                label
                column
                metrics {
                  name
                  description
                  kpi
                }
              }
              password
              sensitive
              variant
            }
          }
        }
      }
    }
  }
`;

interface EditProcessorProps {
  name: string;
  onSaveSuccess: () => void;
  onCancel: () => void;
  readOnly?: boolean;
}

export const EditProcessorDialog: React.FC<EditProcessorProps> = ({
  name,
  readOnly,
  onSaveSuccess,
  onCancel,
}) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [
    editResourceInUseWarningDialogOpen,
    setEditResourceInUseWarningDialogOpen,
  ] = useState<{
    open: boolean;
    values: { [key: string]: any };
    function: "onSave" | "onToggle" | null;
  }>({
    open: false,
    values: {},
    function: null,
  });

  const { data, error } = useGetProcessorWithTypeQuery({
    variables: {
      name,
    },
    fetchPolicy: "network-only",
  });

  // Communicate error if present.
  useEffect(() => {
    if (error != null) {
      enqueueSnackbar(`Error retrieving data for processor ${name}.`, {
        variant: "error",
      });
      console.error(error);
    }
  }, [enqueueSnackbar, error, name]);

  const { data: configurationCount } = useConfigurationCountQuery({
    variables: {
      query: `${Kind.Processor}:${trimVersion(name)}`,
    },
    fetchPolicy: "network-only",
    onError(error) {
      console.error(error);
      enqueueSnackbar(
        `Failed to get configuration count for ${Kind.Processor} ${trimVersion(
          name,
        )}`,
        {
          variant: "error",
        },
      );
    },
  });

  // Open dialog when we have data.
  useEffect(() => {
    if (data != null && configurationCount != null) {
      setOpen(true);
    }
  }, [data, configurationCount, setOpen]);

  function interceptSave(values: { [key: string]: any }) {
    if (data?.processorWithType?.processor == null) {
      enqueueSnackbar("Cannot save processor.", { variant: "error" });
      console.error("no processor found when requesting processor and type");
      return;
    }
    if (configurationCount?.configurationCount == null) {
      enqueueSnackbar("Cannot get configuration count.", { variant: "error" });
      console.error("Cannot get configuration count");
      return;
    }
    if (configurationCount.configurationCount > 1) {
      setEditResourceInUseWarningDialogOpen({
        open: true,
        values: values,
        function: "onSave",
      });
    } else {
      handleSave(values);
    }
  }

  async function handleSave(values: { [key: string]: any }) {
    setEditResourceInUseWarningDialogOpen({
      open: false,
      values: {},
      function: null,
    });

    const processor = new BPProcessor(data?.processorWithType.processor!);
    processor.setParamsFromMap(values);

    processor.metadata.displayName = values.displayName;

    try {
      await processor.apply();
      enqueueSnackbar("Saved processor!", { variant: "success" });
      setOpen(false);
      onSaveSuccess();
    } catch (err) {
      enqueueSnackbar("Error saving processor", { variant: "error" });
      console.error(err);
    }
  }

  function handleClose() {
    setOpen(false);
    onCancel();
  }

  function handleWarnConfirm() {
    if (editResourceInUseWarningDialogOpen.function === "onSave") {
      handleSave(editResourceInUseWarningDialogOpen.values);
      // } else if (editResourceInUseWarningDialogOpen.function === "onToggle") {
      // onTogglePause(); future addition
    } else {
      console.error("Provided function for warn dialog confirm is null");
    }
  }

  return (
    <>
      <EditResourceDialog
        fullWidth
        resourceTypeDisplayName={
          data?.processorWithType?.processorType?.metadata?.displayName ?? ""
        }
        resourceNameField={data?.processorWithType?.processor?.metadata?.name}
        displayName={
          data?.processorWithType.processor?.metadata.displayName ?? ""
        }
        description={
          data?.processorWithType.processorType?.metadata.description ?? ""
        }
        onSave={interceptSave}
        onClose={handleClose}
        onCancel={handleClose}
        additionalInfo={
          data?.processorWithType.processorType?.metadata.additionalInfo
        }
        resourceDocLink={
          data?.processorWithType.processorType?.metadata.resourceDocLink ?? ""
        }
        parameters={data?.processorWithType.processor?.spec.parameters ?? []}
        parameterDefinitions={
          data?.processorWithType.processorType?.spec.parameters ?? []
        }
        paused={data?.processorWithType.processor?.spec.disabled}
        kind={Kind.Processor}
        open={open}
        readOnly={readOnly}
      />
      <EditResourceInUseWarningDialog
        resourceName={data?.processorWithType?.processor?.metadata.name ?? ""}
        resourceKind={Kind.Processor}
        configurationCount={configurationCount?.configurationCount!}
        open={editResourceInUseWarningDialogOpen.open}
        onCancel={() =>
          setEditResourceInUseWarningDialogOpen({
            open: false,
            values: {},
            function: null,
          })
        }
        onConfirm={() => handleWarnConfirm()}
      />
    </>
  );
};

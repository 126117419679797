import { ApolloError } from "@apollo/client";
import { isEqual, isFunction } from "lodash";
import { useSnackbar } from "notistack";
import { useState } from "react";
import {
  GetResourceTypesQuery,
  Kind,
  PipelineType,
  ResourceConfiguration,
  useGetResourceTypeLazyQuery,
} from "../../graphql/generated";
import { UpdateStatus } from "../../types/resources";
import { BPResourceConfiguration } from "../../utils/classes";
import { BPExtension } from "../../utils/classes/extension";
import { BPProcessor } from "../../utils/classes/processor";
import { applyResources } from "../../utils/rest/apply-resources";
import { trimVersion } from "../../utils/version-helpers";
import { ReusableProcessors } from "../ProcessorsDialog/ProcessorDialog";
import { FormValues } from "../ResourceConfigForm";
import { DialogResource } from "../ResourceDialog";
import { AllItemsView } from "./AllItemsView";
import { ChooseView } from "./ChooseView";
import { CreateConfigureView } from "./CreateConfigureView";
import { EditResourceView } from "./EditResourceView";
import { SelectView } from "./SelectView";

enum Page {
  MAIN,
  CREATE_RESOURCE_SELECT,
  CREATE_RESOURCE_CONFIGURE,
  EDIT_RESOURCE,
  CHOOSE_VIEW,
  PREVIEW_RECOMMENDATION_VIEW,
}

interface ResourceConfigurationEditorProps {
  // The initial list of processors or extensions to edit
  initItems: ResourceConfiguration[];
  // The stateful value of the current resource configurations
  items: ResourceConfiguration[];
  // Called to change the current state of resourceConfigurations
  onItemsChange: (newItems: ResourceConfiguration[]) => void;

  readOnly?: boolean;

  // The types of telemetry that are available for the pipeline, used to determine
  // which resource types can be used.
  telemetryTypes: PipelineType[];

  kind: Kind.Processor | Kind.Extension;

  reusableResources?: ReusableProcessors; // or extensions

  fetchReusableResourcesError?: ApolloError;

  refetchReusableResources?: () => void;

  refetchConfiguration: () => void;

  closeDialog: () => void;

  // updateInlineItems will be called when the user saves the inline items.
  // For processors it should handle saving the inline processors on that
  // item, and for extensions it should handle saving the inline extensions
  // on the configuration.  It should return true if saving was successful
  // and false if saving failed. There is no handling in the component, it is
  // up to the provider of the function to handle the saving and error.
  updateInlineItems: (items: ResourceConfiguration[]) => Promise<boolean>;
}

export type ResourceType = GetResourceTypesQuery["resourceTypes"][0];

export const ResourceConfigurationEditor: React.FC<
  ResourceConfigurationEditorProps
> = ({
  items,
  initItems: itemsProp,
  onItemsChange,
  readOnly,
  telemetryTypes,
  kind,
  reusableResources,
  fetchReusableResourcesError,
  refetchReusableResources,
  updateInlineItems,
  refetchConfiguration,
  closeDialog,
}) => {
  const [view, setView] = useState<Page>(Page.MAIN);
  const [newResourceType, setNewResourceType] = useState<ResourceType | null>(
    null,
  );
  const [editingItemIndex, setEditingItemIndex] = useState<number>(-1);
  const [applyQueue, setApplyQueue] = useState<(BPProcessor | BPExtension)[]>(
    [],
  );
  const [previewRecommendation, setPreviewRecommendation] =
    useState<ResourceConfiguration | null>(null);

  const [matchingResources, setMatchingResources] =
    useState<ReusableProcessors>();

  const { enqueueSnackbar } = useSnackbar();

  const [getResourceType] = useGetResourceTypeLazyQuery({
    onError: (err) => {
      console.error(err);
      enqueueSnackbar("Error retrieving resource type", {
        variant: "error",
      });
    },
  });

  /* -------------------------------- Functions ------------------------------- */

  function handleReturnToAll() {
    setView(Page.MAIN);
    setNewResourceType(null);
    setPreviewRecommendation(null);
  }

  function handleReturnToSelectView() {
    setView(Page.CREATE_RESOURCE_SELECT);
    setNewResourceType(null);
    isFunction(refetchReusableResources)
      ? refetchReusableResources()
      : kind !== Kind.Extension &&
        console.error("No function provided for refetchReusableResources");
  }

  // handleSelectNewProcessorType is called when a user selects a processor type
  // in the CreateProcessorSelect view.
  function handleSelectNewResourceType(type: ResourceType) {
    if (fetchReusableResourcesError) {
      console.error(fetchReusableResourcesError);
      enqueueSnackbar("Error retrieving reusable resources", {
        variant: "error",
      });
      isFunction(refetchReusableResources)
        ? refetchReusableResources()
        : console.error("No function provided for refetchReusableResources");
    }
    const matchingResources = reusableResources?.filter((p) => {
      return trimVersion(p.spec.type) === type.metadata.name;
    });
    setNewResourceType(type);
    if (matchingResources && matchingResources?.length > 0) {
      setMatchingResources(matchingResources);
      setView(Page.CHOOSE_VIEW);
    } else {
      setView(Page.CREATE_RESOURCE_CONFIGURE);
    }
  }

  function handleCreateNewResource() {
    setView(Page.CREATE_RESOURCE_CONFIGURE);
  }

  function handleEnterSelectView() {
    setView(Page.CREATE_RESOURCE_SELECT);
    isFunction(refetchReusableResources)
      ? refetchReusableResources()
      : kind !== Kind.Extension &&
        console.error("No function provided for refetchReusableResources");
  }

  // handleAddProcessor adds a new processor to the list of processors
  async function handleAddItem(formValues: FormValues) {
    const itemConfig = new BPResourceConfiguration();
    itemConfig.setParamsFromMap(formValues);
    itemConfig.type = newResourceType!.metadata.name;

    const newItems = [...items, itemConfig];
    onItemsChange(newItems);
    handleReturnToAll();
  }

  // handleSaveExistingInlineResource saves changes to an existing resourceConfiguration in the list
  function handleSaveExistingInlineResource(formValues: FormValues) {
    const itemConfig = new BPResourceConfiguration(items[editingItemIndex]);
    itemConfig.setParamsFromMap(formValues);

    const newItems = [...items];
    newItems[editingItemIndex] = itemConfig;
    onItemsChange(newItems);

    handleReturnToAll();
  }

  // handleSaveExistingPersistentResource adds a processor to the apply queue
  function handleSaveExistingPersistentResource(
    resource: BPProcessor | BPExtension,
  ) {
    const foundIndex = applyQueue.findIndex(
      (p) => p.name() === resource.name(),
    );
    if (foundIndex !== -1) {
      const newApplyQueue = [...applyQueue];
      newApplyQueue[foundIndex] = resource;
      setApplyQueue(newApplyQueue);
    } else {
      setApplyQueue([...applyQueue, resource]);
    }

    handleReturnToAll();
  }

  function handleSaveReusableResourceChoice(resource: DialogResource) {
    const processorConfig = new BPResourceConfiguration();
    processorConfig.name = resource.metadata.name;

    const processors = [...items, processorConfig];
    onItemsChange(processors);

    handleReturnToAll();
  }

  async function onAddToLibrary(values: { [key: string]: any }, name: string) {
    const reusableProcessor = new BPProcessor({
      metadata: {
        name: name,
        id: "",
        version: 0,
        displayName: values.displayName,
      },
      spec: {
        parameters: [],
        type: items[editingItemIndex].type!,
        disabled: items[editingItemIndex].disabled,
      },
    });

    reusableProcessor.setParamsFromMap(values);

    try {
      await applyResources([reusableProcessor]);
      enqueueSnackbar(`Successfully added ${kind} to Library!`, {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (err) {
      enqueueSnackbar(`Failed to add ${kind} to Library.`, {
        variant: "error",
        autoHideDuration: 5000,
      });
      console.error(err);
      return;
    }

    const updatedProcessor = new BPResourceConfiguration({
      name: reusableProcessor.metadata.name,
      disabled: reusableProcessor.spec.disabled,
    });

    // find the old inline item and replace it with this one
    const newItems = items.map((i, idx) => {
      if (idx === editingItemIndex) {
        return updatedProcessor;
      }
      return i;
    });

    onItemsChange(newItems);

    updateInlineItems(newItems);

    isFunction(refetchReusableResources)
      ? refetchReusableResources()
      : console.error("No function provided for refetchReusableResources");
  }

  /**
   * Copy the values from the library resource to the inline resource, and replace the library resource
   *
   * @param values Library resource parameters
   * @param name Library resource name
   */
  async function onUnlinkFromLibrary(
    values: { [key: string]: any },
    name: string,
    type: string,
    unlinkDisplayName: string,
  ) {
    const itemName = name ?? items[editingItemIndex].name;
    const itemConfig = new BPResourceConfiguration(items[editingItemIndex]);
    itemConfig.setParamsFromMap(values);
    itemConfig.name = undefined;
    itemConfig.id = undefined;
    itemConfig.type = type;
    itemConfig.displayName = unlinkDisplayName
      ? unlinkDisplayName
      : itemName
        ? trimVersion(itemName)
        : "";

    const newItems = [...items];
    newItems[editingItemIndex] = itemConfig;

    enqueueSnackbar(
      `Successfully unlinked ${trimVersion(itemName)} from Library!`,
      {
        variant: "success",
        autoHideDuration: 3000,
      },
    );
    onItemsChange(newItems);
    handleReturnToAll();
  }

  // handleRemoveItem removes a processor from the list of processors
  async function handleRemoveItem(index: number) {
    const newItems = [...items];
    newItems.splice(index, 1);
    onItemsChange(newItems);

    handleReturnToAll();
  }

  // handleEditItemClick sets the editing index and switches to the edit page
  function handleEditItemClick(index: number) {
    setEditingItemIndex(index);
    setView(Page.EDIT_RESOURCE);
  }

  // handleSave saves the processors to the backend and closes the dialog.
  async function handleSave() {
    const inlineChange = !isEqual(itemsProp, items);
    const resourceChange = applyQueue.length > 0;
    var shouldCloseDialog = true;

    if (!inlineChange && !resourceChange) {
      closeDialog();
    }

    if (resourceChange) {
      const { updates } = await applyResources(applyQueue);
      if (updates.some((u) => u.status === UpdateStatus.INVALID)) {
        enqueueSnackbar("Failed to save resources", {
          variant: "error",
          key: "save-resources-error",
        });
        shouldCloseDialog = false;
      }
    }

    if (inlineChange) {
      shouldCloseDialog = await updateInlineItems(items);
    }

    if (shouldCloseDialog) {
      refetchConfiguration();
      closeDialog();
      enqueueSnackbar(`Saved ${kind}s!`, { variant: "success" });
    }
  }

  async function handleViewRecommendation(rec: ResourceConfiguration) {
    const { data } = await getResourceType({
      variables: { kind: Kind.ProcessorType, name: rec.type! },
    });

    if (data == null || data.resourceType == null) {
      console.error("Failed to get resource type for recommendation");
      return;
    }

    setNewResourceType(data.resourceType);
    setPreviewRecommendation(rec);
    setView(Page.PREVIEW_RECOMMENDATION_VIEW);
  }

  let current: JSX.Element;
  switch (view) {
    case Page.MAIN:
      current = (
        <AllItemsView
          resourceKind={kind}
          items={items}
          onAddItem={handleEnterSelectView}
          onEditItem={handleEditItemClick}
          onSave={handleSave}
          onItemsChange={onItemsChange}
          onViewRecommendation={handleViewRecommendation}
          readOnly={Boolean(readOnly)}
        />
      );
      break;
    case Page.CREATE_RESOURCE_SELECT:
      current = (
        <SelectView
          resourceKind={kind}
          telemetryTypes={telemetryTypes}
          onBack={() => setView(Page.MAIN)}
          onSelect={handleSelectNewResourceType}
        />
      );
      break;
    case Page.CREATE_RESOURCE_CONFIGURE:
      current = (
        <CreateConfigureView
          resourceKind={kind}
          resourceType={newResourceType!}
          onBack={handleReturnToSelectView}
          onSave={handleAddItem}
          onClose={closeDialog}
        />
      );
      break;
    case Page.EDIT_RESOURCE:
      current = (
        <EditResourceView
          resourceKind={kind}
          items={items}
          editingIndex={editingItemIndex}
          applyQueue={applyQueue}
          onEditInlineSave={handleSaveExistingInlineResource}
          onEditPersistentResourceSave={handleSaveExistingPersistentResource}
          onBack={handleReturnToAll}
          onRemove={handleRemoveItem}
          readOnly={readOnly}
          libraryResources={reusableResources}
          onAddToLibrary={onAddToLibrary}
          onUnlinkFromLibrary={onUnlinkFromLibrary}
        />
      );
      break;
    case Page.CHOOSE_VIEW:
      current = (
        <ChooseView
          resourceKind={kind}
          onBack={handleReturnToSelectView}
          onCreate={handleCreateNewResource}
          reusableResources={matchingResources!}
          selected={newResourceType!}
          handleSaveExisting={handleSaveReusableResourceChoice}
        />
      );
      break;
    case Page.PREVIEW_RECOMMENDATION_VIEW:
      const initValues: FormValues = {
        displayName: previewRecommendation!.displayName!,
      };

      for (const p of previewRecommendation!.parameters!) {
        initValues[p.name] = p.value;
      }
      return (
        <CreateConfigureView
          resourceKind={kind}
          resourceType={newResourceType!}
          onBack={handleReturnToAll}
          onSave={handleAddItem}
          onClose={closeDialog}
          actionButtonText="Accept"
          initValues={initValues}
        />
      );
  }

  return current;
};

import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { PipelineType } from "../../graphql/generated";
import { SnapshotRow } from "./SnapShotRow";
import { Log, Metric, Trace, useSnapshot } from "./SnapshotContext";
import {
  SnapshotRegion,
  findElementWithRegion,
  findRegionElement,
  getRegion,
} from "./regions";
import { EmptyTableIcon } from "../Icons";

import mixins from "../../styles/mixins.module.scss";
import styles from "./snap-shot-console.module.scss";
import { getBindplaneID } from "./utils";

// seconds to fill the loading progress bar, defined in graphql/resolver.go
const snapShotTimeOut = 30;

export enum SnapshotActionType {
  NONE = "",
  OPEN_METRIC_NAME_MENU = "open-metric-name-menu",
  OPEN_FIELD_ROW_MENU = "open-field-row-menu",
  OPEN_SEVERITY_MENU = "open-severity-menu",
  INCLUDE_METRIC = "include-metric",
  EXCLUDE_METRIC = "exclude-metric",
  INCLUDE_FIELD = "include-field",
  EXCLUDE_FIELD = "exclude-field",
  DELETE_FIELD = "delete-field",
  RENAME_FIELD = "rename-field",
  FILTER_SEVERITY_GTE = "filter-severity-gte",
  COPY_OTTL = "copy-ottl",
  COPY_VALUE = "copy-value",
  GROUP_BY_ATTRIBUTES = "group-by-attributes",
}
export interface SnapshotActionData {
  [key: string]: any;
}
export interface SnapshotAction {
  pipelineType: PipelineType;
  type: SnapshotActionType;
  data: SnapshotActionData;
  source?: HTMLElement;
}
export interface SnapshotActionHandler {
  (action: SnapshotAction): void;
}

interface Props {
  hideControls?: boolean;
  logs: Log[];
  metrics: Metric[];
  traces: Trace[];
  footer: string;
  onAction?: SnapshotActionHandler;
  readOnly: boolean;
}

export const SnapshotConsole: React.FC<Props> = memo(
  ({ hideControls, logs, metrics, traces, footer, onAction, readOnly }) => {
    const { loading, pipelineType } = useSnapshot();

    const clickSnapshotConsole = (event: React.MouseEvent<HTMLElement>) => {
      const regionElement = findRegionElement(event.target as Element);
      const region = getRegion(regionElement);
      const data = Object.assign({}, regionElement?.dataset);
      switch (region) {
        case SnapshotRegion.ROW_SUMMARY:
        case SnapshotRegion.EXPANDER:
          const rowElement = findElementWithRegion(
            regionElement ?? null,
            SnapshotRegion.ROW,
          );
          if (!rowElement) {
            return;
          }
          const needsToExpand = !rowElement.classList.contains(styles.open);
          const rowID = rowElement.dataset["rowId"];
          if (!rowID) {
            break;
          }
          const rows = document.querySelectorAll(`[data-row-id="${rowID}"]`);
          rows.forEach((el) => {
            el.classList.toggle(styles.open, needsToExpand);
            if (needsToExpand && rows.length > 1) {
              // opened, scroll both into view
              el.scrollIntoView({ behavior: "smooth", block: "start" });
              // wait until the expansion transition is complete and scroll again. this
              // will ensure that the bottom most element is completely visible instead
              // of expanding below the fold.
              setTimeout(() => {
                el.scrollIntoView({ behavior: "smooth", block: "nearest" });
                setTimeout(() => {
                  el.scrollIntoView({ behavior: "smooth", block: "start" });
                }, 200);
              }, 200); // 200ms is the transition duration in the css
            }
          });
          break;
        case SnapshotRegion.ROW_NAME:
          if (readOnly || !onAction) {
            return;
          }
          onAction({
            pipelineType,
            type: SnapshotActionType.OPEN_METRIC_NAME_MENU,
            source: regionElement,
            data,
          });
          break;
        case SnapshotRegion.SEVERITY:
          if (readOnly || !onAction) {
            return;
          }
          onAction({
            pipelineType,
            type: SnapshotActionType.OPEN_SEVERITY_MENU,
            source: regionElement,
            data,
          });
          break;
        case SnapshotRegion.FIELD_ROW:
          if (readOnly || !onAction) {
            return;
          }
          onAction({
            pipelineType,
            type: SnapshotActionType.OPEN_FIELD_ROW_MENU,
            source: regionElement,
            data,
          });
      }
    };

    return (
      <Stack spacing={2} height="100%">
        {hideControls ? <Box minHeight={45} /> : <></>}
        <Stack
          className={mixins["flex-grow"]}
          data-region={SnapshotRegion.CONSOLE}
          onClick={(event) => clickSnapshotConsole(event)}
          spacing={2}
        >
          <MessagesContainer
            type={PipelineType.Logs}
            display={pipelineType === PipelineType.Logs}
            loading={loading}
            messages={logs}
            footer={footer}
          />

          <MessagesContainer
            type={PipelineType.Metrics}
            display={pipelineType === PipelineType.Metrics}
            loading={loading}
            messages={metrics}
            footer={footer}
          />

          <MessagesContainer
            type={PipelineType.Traces}
            display={pipelineType === PipelineType.Traces}
            loading={loading}
            messages={traces}
            footer={footer}
          />
        </Stack>
      </Stack>
    );
  },
);

const MessagesContainerComponent: React.FC<{
  messages: (Log | Metric | Trace)[] | null;
  type: PipelineType;
  display: boolean;
  loading?: boolean;
  footer: string;
}> = ({ messages, type, display, loading, footer }) => {
  // This useEffect and state are used to update the loading progress bar
  const [progress, setProgress] = useState(0);
  const { searchQuery, isFiltered } = useSnapshot();

  useEffect(() => {
    if (loading) {
      const timer = setInterval(() => {
        // increment the progress bar every 0.5 seconds by 100 * 0.5 / snapShotTimeOut
        const increment = (100 * 0.5) / snapShotTimeOut;
        setProgress((prevProgress) => {
          return prevProgress >= 100 + increment ? 0 : prevProgress + increment;
        });
      }, 500);

      return () => {
        clearInterval(timer);
        setProgress(0);
      };
    }
  }, [loading, setProgress, searchQuery]);

  if (!display) {
    return null;
  }
  return (
    <div className={styles.console}>
      <div className={styles.stack}>
        {loading ? (
          <Stack
            height="100%"
            width={"100%"}
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <EmptyTableIcon />
            <Stack spacing={1} width={"fit-content"}>
              <Typography variant="h5">Searching for telemetry...</Typography>
              <LinearProgress variant="determinate" value={progress} />
            </Stack>
          </Stack>
        ) : (
          <>
            {!messages?.length && (
              <Stack
                height="100%"
                width={"100%"}
                justifyContent="center"
                alignItems="center"
              >
                <Typography color="secondary">No recent {type}</Typography>
              </Stack>
            )}
            {messages?.map((m, ix) => (
              <SnapshotRow
                key={`${type}-${ix}`}
                message={m}
                type={type}
                filtered={isFiltered(type, getBindplaneID(m))}
              />
            ))}
          </>
        )}
      </div>

      <div className={styles.footer}>
        <Typography color="secondary" fontSize={12}>
          {footer}
        </Typography>
      </div>
    </div>
  );
};

export const MessagesContainer = memo(MessagesContainerComponent);

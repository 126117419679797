import { Card, Stack, Typography, IconButton, Tooltip } from "@mui/material";
import { ApolloError } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useState } from "react";
import {
  Kind,
  ResourceConfiguration,
  useGetResourceTypeQuery,
  useGetResourceWithTypeQuery,
} from "../../graphql/generated";
import { BPResourceConfiguration } from "../../utils/classes";
import { BookmarkIcon, EditIcon, MenuIcon } from "../Icons";

import styles from "./resource-configuration-label-card.module.scss";
import colors from "../../styles/colors";

interface LabelCardProps {
  index: number;
  item: ResourceConfiguration;
  resourceKind: Kind.Processor | Kind.Extension;
  dragDropRef?: React.RefObject<HTMLDivElement>;
  isHovered?: boolean;
  onEdit: () => void;
}

export const ResourceConfigurationLabelCard: React.FC<LabelCardProps> = ({
  index,
  resourceKind,
  item,
  dragDropRef,
  isHovered,
  onEdit,
}) => {
  const resourceConfig = new BPResourceConfiguration(item);
  const { enqueueSnackbar } = useSnackbar();
  const [resourceTypeDisplayName, setResourceTypeDisplayName] =
    useState<string>("");
  const [resourceNameOrDescription, setResourceNameOrDescription] =
    useState<string>();

  function onError(error: ApolloError) {
    console.error(error);
    enqueueSnackbar("Error retrieving Resource Type", {
      variant: "error",
      key: "Error retrieving Resource Type",
    });
  }

  const resourceType =
    resourceKind === Kind.Processor ? Kind.ProcessorType : Kind.ExtensionType;

  useGetResourceTypeQuery({
    variables: { kind: resourceType, name: resourceConfig.type! },
    skip: !resourceConfig.isInline(),
    onError,
    onCompleted(data) {
      setResourceTypeDisplayName(data.resourceType!.metadata!.displayName!);
      setResourceNameOrDescription(item.displayName ?? "");
    },
  });

  useGetResourceWithTypeQuery({
    variables: { kind: resourceKind, name: resourceConfig.name! },
    skip: resourceConfig.isInline(),
    onError,
    onCompleted(data) {
      setResourceTypeDisplayName(
        data?.resourceWithType!.resourceType!.metadata!.displayName!,
      );
      setResourceNameOrDescription(
        data?.resourceWithType?.resource?.metadata?.name,
      );
    },
  });

  const labelTitle = `${resourceTypeDisplayName}${resourceNameOrDescription ? `: ${resourceNameOrDescription}` : ""}`;

  return (
    <Card
      variant="outlined"
      ref={dragDropRef}
      style={{
        border: isHovered ? `1px solid ${colors.pixelPointBlue}` : undefined,
      }}
      classes={{ root: styles.card }}
    >
      <Stack
        direction="row"
        alignItems={"center"}
        spacing={1}
        justifyContent={"space-between"}
      >
        <Stack
          direction={"row"}
          spacing={1}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
          width={"100%"}
        >
          <MenuIcon className={styles["hover-icon"]} />
          <Tooltip title={labelTitle}>
            <Typography
              fontWeight={600}
              overflow={"hidden"}
              textOverflow={"ellipsis"}
              whiteSpace={"nowrap"}
              maxWidth={"95%"}
            >
              {resourceNameOrDescription ? (
                <>
                  <span className={styles.displayName}>
                    {resourceTypeDisplayName}:{" "}
                  </span>
                  <span className={styles.shortDescription}>
                    {resourceNameOrDescription}
                  </span>
                </>
              ) : (
                <span className={styles.displayName}>
                  {resourceTypeDisplayName}
                </span>
              )}
            </Typography>
          </Tooltip>
        </Stack>

        <Stack direction={"row"}>
          {resourceConfig.name && (
            <BookmarkIcon
              width={18}
              height={18}
              stroke={colors.pixelPointBlue}
              fill={colors.pixelPointBlue}
              style={{ position: "relative", top: "-8px", left: "-2px" }}
            />
          )}

          <IconButton
            onClick={onEdit}
            data-testid={`edit-${resourceKind.toLowerCase()}-${index}`}
          >
            <EditIcon width={15} height={15} style={{ float: "right" }} />
          </IconButton>
        </Stack>
      </Stack>
    </Card>
  );
};

import { Button } from "@mui/material";
import { useMemo, useState } from "react";
import { LabelEditor } from "../LabelEditor";
import { LabelTuple } from "../LabelEditor/LabelEditor";

interface MultiAgentLabelEditorProps {
  agentIds: string[];
  selectedAgentLabels: Record<string, string>[];
  onSave: () => void;
}

export const MultiAgentLabelEditor: React.FC<MultiAgentLabelEditorProps> = ({
  agentIds,
  selectedAgentLabels,
  onSave,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const tuples = useMemo(
    () => labelsArrayToTupleArray(selectedAgentLabels),
    [selectedAgentLabels],
  );

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        Manage Labels
      </Button>

      <LabelEditor
        agentIds={agentIds}
        popoverProps={{
          open: !!anchorEl,
          anchorEl,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }}
        onClose={() => setAnchorEl(null)}
        onSave={onSave}
        existingLabels={tuples}
      />
    </>
  );
};

/**
 * labelsArrayToTupleArray converts an array of labels to an array of tuples.
 * It filters out any labels we don't want to appear in the editor, such as
 * bindplane/ labels, install ID, or configuration label.
 *
 * @param labelsArray
 */
function labelsArrayToTupleArray(
  labelsArray: Record<string, string>[],
): LabelTuple[] {
  const tuples: LabelTuple[] = [];

  for (const labelSet of labelsArray) {
    const filtered = Object.entries(labelSet).filter(([key]) => {
      return (
        !key.startsWith("bindplane/") &&
        key !== "install_id" &&
        key !== "configuration"
      );
    });

    for (const [key, value] of filtered) {
      if (tuples.some((t) => t[0] === key && t[1] === value)) continue;
      tuples.push([key, value]);
    }
  }

  return tuples;
}

import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import {
  Kind,
  useConfigurationCountQuery,
  useGetDestinationWithTypeQuery,
} from "../../../graphql/generated";
import { BPDestination } from "../../../utils/classes";
import { EditResourceDialog } from "../../ResourceDialog/EditResourceDialog";
import { EditResourceInUseWarningDialog } from "../../Dialogs/EditResourceInUseWarningDialog/EditResourceInUseWarningDialog";
import { trimVersion } from "../../../utils/version-helpers";

interface EditDestinationProps {
  name: string;
  onSaveSuccess: () => void;
  onCancel: () => void;
  readOnly?: boolean;
  isLibraryView?: boolean;
}

export const EditDestinationDialog: React.FC<EditDestinationProps> = ({
  name,
  readOnly,
  onSaveSuccess,
  onCancel,
  isLibraryView,
}) => {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [
    editResourceInUseWarningDialogOpen,
    setEditResourceInUseWarningDialogOpen,
  ] = useState<{
    open: boolean;
    values: { [key: string]: any };
    function: "onSave" | "onToggle" | null;
  }>({
    open: false,
    values: {},
    function: null,
  });

  const { data, error } = useGetDestinationWithTypeQuery({
    variables: {
      name,
    },
    fetchPolicy: "network-only",
  });

  // Communicate error if present.
  useEffect(() => {
    if (error != null) {
      enqueueSnackbar(`Error retrieving data for destination ${name}.`, {
        variant: "error",
      });
      console.error(error);
    }
  }, [enqueueSnackbar, error, name]);

  const { data: configurationCount } = useConfigurationCountQuery({
    variables: {
      query: `${Kind.Destination}:${trimVersion(name)}`,
    },
    fetchPolicy: "network-only",
    onError(error) {
      console.error(error);
      enqueueSnackbar(
        `Failed to get configuration count for ${
          Kind.Destination
        } ${trimVersion(name)}`,
        {
          variant: "error",
        },
      );
    },
  });

  // Open dialog when we have data.
  useEffect(() => {
    if (data != null && configurationCount != null) {
      setOpen(true);
    }
  }, [data, configurationCount, setOpen]);

  function interceptSave(values: { [key: string]: any }) {
    if (data?.destinationWithType?.destination == null) {
      enqueueSnackbar("Cannot save destination.", { variant: "error" });
      console.error(
        "no destination found when requesting destination and type",
      );
      return;
    }
    if (configurationCount?.configurationCount == null) {
      enqueueSnackbar("Cannot get configuration count.", { variant: "error" });
      console.error("Cannot get configuration count");
      return;
    }
    if (configurationCount.configurationCount > 1) {
      setEditResourceInUseWarningDialogOpen({
        open: true,
        values: values,
        function: "onSave",
      });
    } else {
      handleSave(values);
    }
  }

  async function handleSave(values: { [key: string]: any }) {
    setEditResourceInUseWarningDialogOpen({
      open: false,
      values: {},
      function: null,
    });

    const destination = new BPDestination(
      data?.destinationWithType.destination!,
    );
    destination.setParamsFromMap(values);

    try {
      await destination.apply();
      enqueueSnackbar("Saved destination!", { variant: "success" });
      setOpen(false);
      onSaveSuccess();
    } catch (err) {
      enqueueSnackbar("Error saving destination", { variant: "error" });
      console.error(err);
    }
  }

  function handleClose() {
    setOpen(false);
    onCancel();
  }

  function handleWarnConfirm() {
    if (editResourceInUseWarningDialogOpen.function === "onSave") {
      handleSave(editResourceInUseWarningDialogOpen.values);
      // } else if (editResourceInUseWarningDialogOpen.function === "onToggle") {
      // onTogglePause(); future addition
    } else {
      console.error("Provided function for warn dialog confirm is null");
    }
  }

  return (
    <>
      <EditResourceDialog
        fullWidth
        resourceTypeDisplayName={
          data?.destinationWithType.destinationType?.metadata?.displayName ?? ""
        }
        resourceNameField={
          data?.destinationWithType?.destination?.metadata?.name ?? ""
        }
        showLibraryBookmark={!isLibraryView}
        description={
          data?.destinationWithType.destinationType?.metadata.description ?? ""
        }
        onSave={interceptSave}
        onClose={handleClose}
        onCancel={handleClose}
        additionalInfo={
          data?.destinationWithType.destinationType?.metadata.additionalInfo
        }
        resourceDocLink={
          data?.destinationWithType.destinationType?.metadata.resourceDocLink ??
          ""
        }
        parameters={
          data?.destinationWithType.destination?.spec.parameters ?? []
        }
        parameterDefinitions={
          data?.destinationWithType.destinationType?.spec.parameters ?? []
        }
        paused={data?.destinationWithType.destination?.spec.disabled}
        kind={Kind.Destination}
        open={open}
        readOnly={readOnly}
      />
      <EditResourceInUseWarningDialog
        resourceName={
          data?.destinationWithType?.destination?.metadata.name ?? ""
        }
        resourceKind={Kind.Destination}
        configurationCount={configurationCount?.configurationCount!}
        open={editResourceInUseWarningDialogOpen.open}
        onCancel={() =>
          setEditResourceInUseWarningDialogOpen({
            open: false,
            values: {},
            function: null,
          })
        }
        onConfirm={() => handleWarnConfirm()}
      />
    </>
  );
};
